import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffffed;
  box-shadow: 0px -1px 20px 1px #4a494970;

  ul {
    li a {
      color: #333333 !important;
      font-size: 1rem;
      font-weight: 500;
    }
    & li button {
      color: #333333 !important;
      font-size: 1rem;
      font-weight: 500;
      background-color: transparent !important;
      outline: 0 !important;
      box-shadow: none !important;
      border: none !important;
    }
    & li a:hover {
      text-decoration: none;
    }
  }

  .tam-350 {
    width: 350px;
  }

  .dropdown-toggle::after {
    display: none;
  }
  .btn-wpp {
    right: 5%;
    background: #5a853d;
    color: #fff;
    font-size: 1.3rem;
    box-shadow: 0px -1px 20px 1px #4a494970;
    padding: 3px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .dropdown-item:nth-child(odd) {
    background-color: #f8f8f8;
  }
  .dropdown-item:nth-child(even) {
    background-color: transparent !important;
  }
  .dropdown-menu {
    padding: 0;
    text-align: center;
  }
  .menu-btn {
    border: none;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 12px;
      background: #333;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      transition: all 0.2s;
      opacity: 0;
    }
    &__icon {
      position: relative;
      width: 50px;
      height: 40px;
      margin: 0 auto;
      cursor: pointer;
      span span {
        background-color: #5a853d;
      }
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon--menu {
    span {
      display: block;
      width: 50px;
      height: 4px;
      background: #333;
      transition: all 0.18s;
      transition-timing-function: $transition;
      transition-delay: 0.05s;
      &:not(last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .icon--close {
    transform: scale(0);
    transform-origin: center center;
    transition: all 0.18s;
    transition-delay: 0.08s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
    opacity: 0;

    span {
      position: relative;
      display: block;
      width: 50px;
      height: 4px;
      background: #333;
      &:nth-of-type(1) {
        top: 17px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        top: 13px;
        transform: rotate(-45deg);
      }
    }
  }

  .is--active {
    .icon--menu {
      span {
        width: 0;
      }
    }
    .icon--close {
      transform: scale(1);
      opacity: 1;
    }
  }

  .is--clicked {
    &:after {
      animation: btnClick 0.35s;
      animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
    }
  }

  @keyframes btnClick {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 0.35;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .vh-100 {
    height: 100vh;
  }
  .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  .slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  @-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
  }
  img {
    max-width: none;
  }

  .menu-show-mobile {
    background-color: transparent;
  }

  @media (max-width: 1199px) {
    .tam-250 {
      width: 250px;
    }
  }

  @media (min-width: 992px) {
    .md-none {
      display: none;
    }
    .navbar-nav {
      flex-direction: row !important;
    }
    .btn-wpp {
      position: fixed;
      bottom: 0;
    }
  }
  @media (max-width: 991px) {
    .sm-none {
      display: none;
    }
    .w-sm-100-logo {
      width: 100%;
    }
    .n-w-overflow {
      height: 88vh !important;
      overflow: auto;
    }
    .dropdown-menu.show {
      background-color: transparent !important;
      border: none;
      transform: translate3d(0, 0, 0) !important;
      position: inherit !important;
    }
    .dropdown-item {
      background-color: transparent !important;
    }
    .dropdown {
      display: flex;
      flex-direction: column;
      margin: 0.8rem 0;
    }
    .show {
      button {
        color: #5a853d !important;
        font-weight: bold;
      }
    }
    .btn-wpp {
      position: absolute;
      top: 45vh;
      right: 0;
      background: #5a853d;
      color: #fff;
      padding: 20px 4px;
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
      font-size: 24px;
    }
  }
  @media (max-width: 524px) {
    .w-sm-100 {
      width: 100%;
    }
  }
  @media (min-width: 1920px) {
  }
`;
