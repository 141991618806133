import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Conteudo, Bgray } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

import psiquiatra from '~/assets/informacoes/psiquiatra.png';
import psiquiatraMobile from '~/assets/informacoes/psiquiatra-mobile.png';

function Home() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar11')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar11'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Priscila Rocco: Quando procurar um psiquiatra?</title>
        <meta
          name="description"
          content="Saiba quando procurar um psiquiatra e como a família pode ajudar no processo e desmitifique a internação psiquiátrica ."
        />
      </Helmet>
      <Conteudo>
        <div className="bg-green">
          <div className="container">
            <h1 className="text-center py-md-5 py-4 mb-0">
              Quando procurar um psiquiatra?
            </h1>
          </div>
        </div>
      </Conteudo>

      <Bgray>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <img
                src={psiquiatra}
                className="d-none d-lg-block"
                alt="Quando procurar um psiquiatra?"
              />
              <img
                src={psiquiatraMobile}
                className="d-lg-none"
                alt="Quando procurar um psiquiatra?"
              />
              <div className="row justify-content-center">
                <div className="col-lg-10 justify">
                  <p className="mt-5">
                    Todos os transtornos mentais tem uma característica em
                    comum: fazem sofrer. E tanto o paciente quanto quem está à
                    sua volta sofre com a doença. A questão é o quanto e quando
                    conseguimos perceber esse sofrimento.
                  </p>
                  <p>
                    Em grande parte das vezes, só se percebe a doença através
                    dos prejuízos que ela provoca, como piora do rendimento no
                    trabalho ou nos estudos, as discussões frequentes em casa, o
                    afastamento dos amigos. Também nesse campo, nem sempre é
                    fácil distinguir causa e efeito. Será que ‘ficamos
                    deprimidos’ porque estamos em dificuldades financeiras ou
                    estamos em dificuldades financeiras porque, devido à
                    depressão, já não conseguimos trabalhar como antes? O
                    consumo de álcool aumentou devido à crise no casamento ou a
                    relação se desgastou porque a bebida começou a tomar o lugar
                    do afeto?
                  </p>
                  <p className="mb-5">
                    É frequente termos mais de um doente na mesma família, mas,
                    ir ao psiquiatra não é questão de competição. Trocando em
                    miúdos: não interessa quem está “mais doente” porque, quem
                    está doente, precisa se tratar. Ou você vai deixar de ir ao
                    hospital porque a febre do seu marido está mais alta que a
                    sua ou o nariz dele mais entupido que o seu?
                    <br />
                    Saber a hora de procurar um psiquiatra é como saber a hora
                    de procurar qualquer outro especialista. Quanto antes,
                    melhor.
                  </p>
                  <h2 className="mostrar11 justify">
                    <img src={setaClosed} alt="seta" className="seta " />
                    Qual profissional procurar?
                  </h2>
                  <div className="d-none justify">
                    <p>
                      Muita gente não sabe, mas existem várias diferenças entre
                      esses profissionais, cujas funções se complementam.
                    </p>
                    <h3 className="h4 subTitleQuestion">Psiquiatra</h3>
                    <p>
                      <b>Psiquiatras</b> são médicos especialistas, ou seja,
                      cursam a Faculdade de Medicina por 6 anos e depois, um
                      programa de residência médica em Psiquiatria, por mais 3
                      anos. O diagnóstico e a indicação do tipo de tratamento a
                      ser seguido é feita por esse profissional.
                    </p>
                    <h3 className="h4 subTitleQuestion">Neurologista</h3>
                    <p>
                      <b>Neurologistas</b> também são médicos especialistas.
                      Enquanto os psiquiatras têm sua atuação mais voltada para
                      os problemas relacionados às emoções e ao comportamento
                      (Depressão, Transtorno de Déficit de Atenção e
                      Hiperatividade etc), os neurologistas focam suas
                      atividades em outras doenças do sistema nervoso, como as
                      que acometem a motricidade e a sensibilidade (Esclerose
                      múltipla, Doença de Parkinson etc).
                    </p>
                    <p>
                      Como tanto os transtornos neurológicos quanto os
                      psiquiátricos envolvem, via de regra, alterações na
                      anatomia e/ou no funcionamento cerebral, é comum que
                      ocorram simultaneamente sintomas de ambas áreas durante o
                      curso de uma mesma doença e assim um mesmo paciente seja
                      acompanhado por dois profissionais.
                    </p>
                    <h3 className="h4 subTitleQuestion">Psicólogo</h3>
                    <p>
                      <b>Psicólogos</b> são profissionais formados em faculdades
                      de Psicologia, cujo curso tem duração de 5 anos. Essa
                      formação os habilita a realizar acompanhamento psicológico
                      (terapia e/ou análise) bem como a aplicação de testes
                      auxiliares ao diagnóstico (como avaliações
                      neuropsicológicas ou de personalidade), mas eles não podem
                      prescrever nenhum remédio. Terapia e medicação são formas
                      de tratamento e uma não exclui a outra. Diversos estudos
                      comprovam que o tratamento combinado (medicação e terapia)
                      é mais eficaz que qualquer um dos dois isoladamente.
                    </p>
                  </div>
                  <h2 className="mostrar11 justify">
                    <img src={setaClosed} alt="seta" className="seta " />
                    Como os amigos e familiares podem ajudar?
                  </h2>
                  <div className="d-none justify">
                    <p>
                      Em geral, essas pessoas têm um contato muito mais próximo
                      com o paciente do que qualquer outro profissional. Assim
                      sendo, conseguem fornecer informações valiosas e ajudar
                      tanto no diagnóstico (p. ex. quando a mãe conta como o
                      filho se comportava na infância) quanto no tratamento (p.
                      ex. quando o filho conta que a mãe tem ficado confusa e
                      ‘misturado’ a medicação).
                    </p>
                    <p>
                      Toda ajuda é bem-vinda, desde que respeitados os limites
                      éticos da relação médico-paciente. O paciente tem direito
                      ao sigilo e, portanto, amigos e familiares podem trazer
                      informações, mas só terão acesso aquelas que o paciente
                      quiser revelar. O sigilo só é quebrado quando o paciente
                      estiver colocando em risco a si mesmo ou a terceiros.
                    </p>
                    <p>
                      Existem diversas associações e materiais de apoio
                      destinados a amigos e familiares de portadores de{' '}
                      <b>transtornos mentais</b>. Se você quer ajudar, não
                      hesite em contatar o médico para ser orientado sobre qual
                      a melhor maneira de fazê-lo.
                    </p>
                  </div>
                  <h2 className="mostrar11 justify">
                    <img src={setaClosed} alt="seta" className="seta " />E se o
                    paciente não quiser ir ao psiquiatra?
                  </h2>
                  <div className="d-none justify">
                    <p>
                      Embora a recusa a ir a um <b>psiquiatra</b> seja algo
                      relativamente comum, cada caso é único e ninguém conhece o
                      paciente melhor que a própria família. Por isso, é a
                      família quem vai elaborar uma solução. Aí vão algumas
                      dicas:
                    </p>
                    <h3 className="h4 subTitleQuestion">
                      Veja com quem o paciente se identifica mais
                    </h3>
                    <p>
                      Às vezes é o momento de explorar o melhor e o pior de cada
                      relação: o paciente pode aceitar ir ao médico tanto para
                      agradar quanto para contrariar alguém da família.
                    </p>
                    <h3 className="h4 subTitleQuestion">
                      Dificulte o acesso ao dinheiro ou carro, por exemplo
                    </h3>
                    <p>
                      Fica mais fácil se sentir motivado a trabalhar ou a seguir
                      uma recomendação (como ir ao médico), quando existe uma
                      necessidade concreta (dinheiro para tingir o cabelo ou
                      pagar o táxi até o shopping).
                    </p>
                    <h3 className="h4 subTitleQuestion">
                      Certifique-se de que não há envolvimento com drogas
                    </h3>
                    <p>
                      Aqui também inclui remédios para emagrecer, álcool ou
                      abuso de outras medicações, porque isso pode piorar
                      algumas características da pessoa (transformar a rispidez
                      em agressividade, p. ex.) ou deixá-la desconfiada ou mesmo
                      paranoica.
                    </p>
                    <h3 className="h4 subTitleQuestion">
                      Dose as palavras, mas não minta
                    </h3>
                    <p>
                      Se o paciente descobrir que você mentiu, ele não vai mais
                      confiar em você.
                    </p>
                    <p>
                      Em casos muito graves, pode ser necessário levar o
                      paciente a um Pronto Socorro e solicitar uma avaliação
                      psiquiátrica lá (interconsulta) ou o psiquiatra pode ir
                      até a casa do paciente (visita domiciliar). Algumas{' '}
                      <b>clínicas psiquiátricas</b> disponibilizam avaliação de
                      urgência e, conforme essa avaliação, o paciente pode ficar
                      em observação ou ser internado ou mesmo liberado para
                      casa. Esse tipo de situação precisa ser discutida com o
                      profissional que vai seguir o paciente e com toda a
                      família.
                    </p>
                  </div>
                  <h2 className="mostrar11 justify">
                    <img src={setaClosed} alt="seta" className="seta " />
                    Desmistificando a internação psiquiátrica
                  </h2>
                  <div className="d-none justify">
                    <p>
                      Qualquer tratamento médico pode ser feito em ambulatório /
                      consultório ou dentro de um hospital, depende da gravidade
                      do quadro ou dos recursos que serão utilizados no
                      tratamento. Em ambos os casos, isso é determinado pelo
                      médico.
                    </p>
                    <p>
                      A <b>internação</b> pode ser voluntária ou compulsória.
                      Nesse último caso, o Ministério Público precisa ser
                      informado e o médico responsável pelo caso deve fazê-lo em
                      até 72 horas. Nenhuma internação tem como finalidade a
                      mera restrição de liberdade ou qualquer caráter punitivo.
                      Trata-se de uma necessidade imposta pela doença.
                    </p>
                    <p>
                      A <b>internação psiquiátrica</b> por si só não é um
                      tratamento, mas parte desse. Ela deve incluir, além da
                      permanência em um ambiente protegido e avaliações
                      constantes por toda a equipe (médico, enfermeiros e
                      auxiliares, terapeuta ocupacional, educador físico,
                      psicólogo), a participação em atividades terapêuticas e o
                      uso correto das medicações prescrita.
                    </p>
                    <p>
                      Em casa, dificilmente a família consegue ficar o tempo
                      todo com o paciente e é mais fácil para este esquivar-se
                      das restrições impostas (como não trancar o quarto nem
                      manusear nenhuma medicação) e burlar a vigilância (sair
                      escondido ou convencer o familiar a permitir que saia
                      desacompanhado). Outro ponto é que a família, por mais
                      bem-intencionada que esteja, não consegue avaliar o
                      paciente de modo imparcial e técnico, o que é vital para a
                      condução do caso. É fundamental que, tanto durante quanto
                      depois da internação, a família esteja presente e seja
                      parceira do médico no tratamento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Bgray>
    </>
  );
}

export default Home;
