import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Esquizofrenia() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar8')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar8'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Especialidades: O que é esquizofrenia?</title>
        <meta
          name="description"
          content="Saiba o que é esquizofrenia, quais são os tipos e tratamentos."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Esquizofrenia:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    A esquizofrenia é uma doença marcada por sintomas positivos,
                    negativos e cognitivos. O que isso significa?
                  </p>
                  <p className="px-5 texto-desk">
                    A esquizofrenia é uma doença marcada por sintomas positivos,
                    negativos e cognitivos.
                    <br /> O que isso significa? <br />
                    Sintomas positivos são delírios, alucinações e
                    desorganização do comportamento.
                    <br />
                    Delírios são ideias fixas que não condizem com a realidade,
                    por exemplo uma dona de casa que se recusa a sair de casa
                    porque acredita que seus documentos podem ser clonados e
                    usados para fazer alienígenas se passarem por ela. <br />
                    Alucinações são percepções sem que haja nenhum estímulo, por
                    exemplo um homem em São Paulo que acredita escutar os
                    pensamentos da sua mãe na Bahia ou uma voz qualquer o
                    acusando ou xingando mesmo ele estando sozinho em casa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                A desorganização do comportamento acontece por exemplo quando o
                paciente fica estático quando precisa se mexer, por vezes fica
                horas na mesma posição, ou não para de andar em círculos quando
                precisa ficar quieto para se alimentar, ou faz coisas sem
                sentido como abrir e fechar gavetas sem parar.
              </p>
              <p className="text-justify texto-desk">
                Sintomas negativos são apatia (o paciente parece não se importar
                com nada nem ter vontade de fazer nada) desorganização do
                pensamento (ou o paciente delira, fala que está sendo
                perseguido, ou fala coisas disparatadas, como uma sopa de
                palavras sem sentido – por exemplo: “Eu gosto de café porque
                abacaxi é amarelo”) e dificuldade em manifestar emoções, o que é
                descrito em psiquiatria como afeto anormal, por exemplo o
                paciente pode falar de coisas sérias rindo como uma criança ou
                falar de coisas tristes sem demonstrar nenhum sentimento ou até
                se mostrando alegre sem estar se sentindo assim.{' '}
              </p>
              <p className="text-justify texto-desk">
                Sintomas cognitivos são dificuldades de atenção, aprendizado e
                resolução de problemas, que chamamos de disfunção executiva.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Sintomas positivos são delírios, alucinações e desorganização do
                comportamento.
                <br />
                Delírios são ideias fixas que não condizem com a realidade, por
                exemplo uma dona de casa que se recusa a sair de casa porque
                acredita que seus documentos podem ser clonados e usados para
                fazer alienígenas se passarem por ela. <br />
                Alucinações são percepções sem que haja nenhum estímulo, por
                exemplo um homem em São Paulo que acredita escutar os
                pensamentos da sua mãe na Bahia ou uma voz qualquer o acusando
                ou xingando mesmo ele estando sozinho em casa.
                <br />
                <br />
                A desorganização do comportamento acontece por exemplo quando o
                paciente fica estático quando precisa se mexer, por vezes fica
                horas na mesma posição, ou não para de andar em círculos quando
                precisa ficar quieto para se alimentar, ou faz coisas sem
                sentido como abrir e fechar gavetas sem parar.
                <br />
                <br />
                Sintomas negativos são apatia (o paciente parece não se importar
                com nada nem ter vontade de fazer nada) desorganização do
                pensamento (ou o paciente delira, fala que está sendo
                perseguido, ou fala coisas disparatadas, como uma sopa de
                palavras sem sentido – por exemplo: “Eu gosto de café porque
                abacaxi é amarelo”) e dificuldade em manifestar emoções, o que é
                descrito em psiquiatria como afeto anormal, por exemplo o
                paciente pode falar de coisas sérias rindo como uma criança ou
                falar de coisas tristes sem demostrar8 nenhum sentimento ou até
                se mostrando alegre sem estar se sentindo assim.{' '}
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Sintomas cognitivos são dificuldades de atenção, aprendizado e
                resolução de problemas, que chamamos de disfunção executiva.
              </p>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />A
                esquizofrenia pode ser classificada em tipos?
              </h2>
              <div className="d-none justify">
                <p>Sim. Classificamos a esquizofrenia em 6 tipos: </p>
                <h3>Paranoide</h3>
                <p className="pb-2">
                  É o tipo mais comum e nele predominam os delírios e
                  alucinações. Chama-se paranoide porque os delírios tendem a
                  ser de perseguição (paranoia), embora possam acontecer
                  delírios megalomaníacos (de grandeza) ou relacionados à
                  religião ou mesmo a qualquer conteúdo bizarro.
                </p>
                <h3>Catatônica</h3>
                <p className="pb-2">
                  Aqui o que predomina é a desorganização do comportamento
                  (imobilidade ou dificuldade de ficar quieto ou atividades sem
                  sentido).
                </p>
                <h3>Hebefrênica</h3>
                <p className="pb-2">
                  Nesse caso a maior alteração é o afeto anormal (dificuldade na
                  expressão de emoções).
                </p>
                <h3>Indiferenciada</h3>
                <p className="pb-2">
                  É a que reúne características de mais de um tipo, não sendo
                  possível classificar como uma ou outra (por exemplo um
                  paciente com delírios bizarros e expressão afetiva igual a de
                  uma criança).
                </p>
                <h3>Simples</h3>
                <p className="pb-2">
                  É um quadro em que o paciente fica progressivamente excêntrico
                  e apático, sem que isso tenha sido precedido por delírios e
                  alucinações.
                </p>
                <h3>Residual</h3>
                <p className="pb-2">
                  Como o nome já indica, é o que sobra quando desaparecem os
                  sintomas positivos, ou seja, o paciente já não delira nem
                  alucina, mas não consegue se reintegrar à sociedade por conta
                  dos sintomas negativos e cognitivos (fica apático e com grande
                  dificuldade de resolver problemas, como os que enfrentamos
                  diariamente no trabalho e nos estudos).
                </p>
              </div>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar a esquizofrenia?
              </h2>
              <p className="d-none justify">
                O quadro geralmente começa de modo abrupto, com delírios e
                alucinações no caso da esquizofrenia paranoide, que é a mais
                frequente. A família e os amigos percebem que o paciente está
                cada vez mais desconfiado e agindo de modo estranho (como, por
                exemplo, falar ‘sozinho’ ou com as alucinações).
                <br />
                <br />
                Nos outros tipos, o início pode ser mais gradual, com o
                desenvolvimento de uma expressão anormal das emoções
                (esquizofrenia hebefrênica) ou com o comportamento motor bizarro
                (esquizofrenia catatônica).
              </p>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade a esquizofrenia é mais frequente?
              </h2>
              <p className="d-none justify">
                Na maioria dos casos, começa no final da adolescência e início
                da fase de adulto jovem. A esquizofrenia hebefrênica tende a
                começar mais cedo e ter uma evolução pior do que a esquizofrenia
                paranoide.
              </p>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento?
              </h2>
              <p className="d-none justify">
                O uso de medicamento é obrigatório nesses casos. Existem
                diversos antipsicóticos (remédios para esquizofrenia) no mercado
                e estão disponíveis para uso via oral e injetável
                (intramuscular). Os injetáveis têm uma duração de ação variável.
                Pode ser de horas até 3 meses, ou seja, o tratamento pode ser
                feito com 1 comprimido por dia até 1 injeção a cada 3 meses.
                Tudo depende do quadro clínico do paciente.
                <br />
                <br />
                Cabe lembrar que o paciente de fato acredita que está sendo
                perseguido e que as vozes são reais, mesmo quando todo mundo diz
                o contrário. Ou seja, para ele, é tudo verdade e ele sofre por
                isso. Argumentar com o paciente é inútil. Também não se deve
                concordar. A melhor saída é dizer ao paciente que entendemos
                como ele está se sentindo. Se você estivesse sendo perseguido e
                passasse o dia ouvindo vozes, provavelmente também ficaria
                estressado, irritado e dormiria mal. Seria bom ter um médico que
                o ajudasse a lidar com todo esse estresse e essa é a hora de
                levar o paciente ao psiquiatra.
                <br />
                <br />
                Os remédios para esquizofrenia farão com que o paciente pare de
                delirar e alucinar, mas não fazem com que ele entenda que aquilo
                não aconteceu de verdade. Ou seja, com o tratamento, o paciente
                vai dizer que já não ouve nada quando está sozinho e que pararam
                de persegui-lo, mas nunca vai dizer que aquilo foi uma psicose
                que já passou. E não tem problema nenhum nisso. O importante é
                que ele faça uso constante da medicação, não use drogas, pare de
                sofrer e, na medida do possível, tenha uma vida produtiva.
                <br />
                <br />
                Todo remédio tem efeitos colaterais e os antipsicóticos não são
                exceção. Por isso é necessário cuidado na escolha do remédio e
                no ajuste de dose porque, se os efeitos colaterais forem muito
                intensos, o paciente abandonará o tratamento. Existem cerca de
                20 opções de remédios desse tipo no mercado brasileiro. Não tem
                desculpa para não encontrar um que se ajuste ao paciente. Claro
                que nem todos os pacientes melhoram. Cerca de 1/3 não melhoram
                com remédios convencionais e para eles está indicada uma
                medicação que precisa de controle com exames de sangue
                (clozapina), que pode também ser associada à
                eletroconvulsoterapia (ECT).
              </p>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar o paciente com esquizofrenia?
              </h2>
              <p className="d-none justify">
                É importante que a família mantenha contato com o psiquiatra,
                porém nunca escondido do paciente. Se ele se sentir traído ou
                enganado, não confiará mais no profissional que o acompanha e
                abandonará o tratamento. A família deve, sempre que possível,
                acompanhar as consultas e dizer na presença do paciente se ele
                está usando drogas, se parou o remédio ou se tem se mostrado
                mais estressado ou insone. Cuidado no jeito de se posicionar.
                Tudo pode ser dito, mas com cautela e nunca num tom acusatório.
                O familiar deve contar se o paciente parece preocupado com o
                olhar da vizinhança e evitar frases do tipo: “Ele não tira
                aquelas ideias absurdas da cabeça”. Afinal, para ele, o delírio
                não é absurdo, é real.
              </p>
              <h2 className="mostrar8 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Tenho esquizofrenia. Posso engravidar?
              </h2>
              <p className="d-none justify">
                Alguns antipsicóticos estão mais associados a diabetes
                gestacional, contudo não são restrição absoluta para gravidez,
                como alguns estabilizadores de humor. Parte dos esquizofrênicos
                tem quadros mais leves e são capazes de encarar a
                maternidade/paternidade. A restrição não está no remédio, está
                no quadro clínico. Pacientes graves não devem engravidar.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Esquizofrenia;
