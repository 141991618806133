import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { FaSpinner } from 'react-icons/fa';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import { Form, Input, Textarea } from '@rocketseat/unform';
import { Container } from './styles';
import api from '~/services/api';

import Banner from '~/assets/contato/agendamento.png';

const schema = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  email: Yup.string().required('O e-mail é obrigatório'),
  telefone: Yup.string(),
  cel: Yup.string().required('O celular é obrigatória'),
  mensagem: Yup.string().required('A mensagem é obrigatória'),
});
function Contato() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(
    { nome, email, telefone, cel, mensagem },
    { resetForm }
  ) {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append('nome', nome);
    formData.append('email', email);
    formData.append('telefone', telefone);
    formData.append('cel', cel);
    formData.append('mensagem', mensagem);

    const response = await api.post('enviar-email', formData);

    if (response.data) {
      swal
        .fire('Bom Trabalho!', 'Logo entraremos em contato!', 'success')
        .then(_ => {
          resetForm();
        });
    } else {
      swal.fire(
        'Ocorreu um erro',
        'Desculpe o incômodo, mas um erro inesperado aconteceu tente novamente;',
        'error'
      );
    }
    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Priscila Rocco: Consultório psiquiátrico</title>
        <meta
          name="description"
          content="Conheça a clínica psiquiátrica da Dra. Priscila Rocco. Um ambiente que preza pela privacidade e é planejado para atendimentos individuais e familiares."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 p-0 ">
              <img src={Banner} alt="Banner contato" />
            </div>
            <div className="col-md-6 h-fit-content-mobile">
              <div className="row align-items-center justify-content-center h-100  form-row">
                <div className="col-sm-8 px-4 px-sm-0">
                  <h1 className="mb-4">Entre em contato</h1>
                  <Form schema={schema} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <Input
                            type="name"
                            className="form-control"
                            name="nome"
                            aria-describedby="nome"
                            placeholder="Nome:"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            aria-describedby="email"
                            placeholder="E-mail:"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-md-6">
                          <div className="form-group">
                            <Input
                              type="tel"
                              className="form-control"
                              name="telefone"
                              aria-describedby="telefone"
                              placeholder="Telefone:"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Input
                              type="cel"
                              className="form-control"
                              name="cel"
                              aria-describedby="cel"
                              placeholder="Celular:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <Textarea
                            type="text"
                            className="form-control"
                            name="mensagem"
                            aria-describedby="mensagem"
                            placeholder="Mensagem:"
                            rows="4"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="submit"
                          dissable={loading}
                          className="btn w-100 py-3"
                        >
                          {loading ? (
                            <FaSpinner
                              size={20}
                              color="#43b0b0"
                              className="spin"
                            />
                          ) : (
                            'ENVIAR'
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                  <a href="tel:+551132850770">
                    <button type="button" className="btn-gray py-2 px-4 my-3">
                      Ligue para (11) 3285-0770
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.2873284605166!2d-46.67841728502221!3d-23.558121884684535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5783f473c2ed%3A0xacea8f3cf981dc2b!2sR.%20Alves%20Guimar%C3%A3es%2C%20462%20-%20Pinheiros%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005410-000!5e0!3m2!1spt-BR!2sbr!4v1606483333336!5m2!1spt-BR!2sbr"
        width="100%"
        height="450"
        frameBorder="0"
        allowFullScreen=""
        title="mapa"
      />
    </>
  );
}

export default Contato;
