import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Carousel from 'react-elastic-carousel';
import Item from './Item';
import { Container, Bgray } from './styles';

import Banner from '~/assets/medica/1.png';
import ansiedade from '~/assets/home/ansiedade.png';
import bipolaridade from '~/assets/home/bipolaridade.png';
import depressao from '~/assets/home/depressao.png';
import dismorfobia from '~/assets/home/dismorfobia.png';
import esquizofrenia from '~/assets/home/esquizofrenia.png';
import obesidade from '~/assets/home/obesidade.png';
import panico from '~/assets/home/panico.png';
import social from '~/assets/home/social.png';
import toc from '~/assets/home/toc.png';
import Consultorio1 from '~/assets/home/1.png';
import Consultorio2 from '~/assets/home/2.png';
import Consultorio3 from '~/assets/home/3.png';
import Consultorio4 from '~/assets/home/4.png';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 768, itemsToShow: 3 },
];

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Priscila Rocco: Psiquiatra e Psicoterapeuta</title>
        <meta
          name="description"
          content="Dra. Priscila Rocco é médica psiquiatra e atua nas áreas clínica e de psicoterapia. Tem graduação e doutorado pela Faculdade de Medicina da USP e atualmente faz parte do corpo clínico do Hospital Sírio Libanês."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100">
            <div className="col-md-6">
              <img src={Banner} alt="Banner Profa Dra Priscila Rocco" />
            </div>
            <div className="col-md-6">
              <div className="row align-items-center h-100">
                <div className="col-md-8 px-5 px-md-auto mt-3 mt-md-0">
                  <h1 className="display-2 font-times title">
                    <span className="h1 h1-small font-times">
                      Prof<sup className="h4 h4-small font-times">a </sup>Dr
                      <sup className="h4 h4-small font-times">a </sup>
                    </span>
                    <br />
                    Priscila Rocco
                  </h1>
                  <h2 className="h6 pr-lg-5">
                  Psiquiatra no Hospital Sírio-Libanês e professora adjunta de Saúde Mental na Faculdade de Medicina de Jundiaí 
                  com doutorado e graduação pela Faculdade de Medicina da USP
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Bgray>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-10 px-0">
              <h2 className="mb-4 text-center text-lg-left">Especialidades</h2>
            </div>
          </div>
          <Carousel breakPoints={breakPoints}>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/depressao`}>
                <img src={depressao} alt="depressao" />
                <h2 className="h6 mb-3">Depressão</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/transtorno-bipolar`}>
                <img src={bipolaridade} alt="bipolaridade" />
                <h2 className="h6 mb-3">Bipolaridade</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/ansiedade-generalizada`}>
                <img src={ansiedade} alt="ansiedade" />
                <h2 className="h6 mb-3">Ansiedade</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/obesidade`}>
                <img src={obesidade} alt="obesidade" />
                <h2 className="h6 mb-3">Obesidade</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/toc`}>
                <img src={toc} alt="toc" />
                <h2 className="h6 mb-3">TOC</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/sindrome-panico`}>
                <img src={panico} alt="sindrome do panico" />
                <h2 className="h6 mb-3">Síndrome do pânico</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/fobia-social`}>
                <img src={social} alt="fobia social" />
                <h2 className="h6 mb-3">Fobia Social</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/esquizofrenia`}>
                <img src={esquizofrenia} alt="esquizofrenia" />
                <h2 className="h6 mb-3">Esquizofrenia</h2>
              </Link>
            </Item>
            <Item>
              <Link to={`${process.env.PUBLIC_URL}/transtorno-dismorfico`}>
                <img src={dismorfobia} alt="dismorfofobia" />
                <h2 className="h6 mb-3">Dismorfofobia</h2>
              </Link>
            </Item>
          </Carousel>
          <div className="row justify-content-center">
            <div className="col-10 px-0 text-center text-lg-left">
              <h2 className="mb-2">Consultório</h2>
              <p className="mb-5">
                Ambiente pronto para recebê-lo com toda privacidade,
                individualmente ou com grupos familiares, quando indicado.
              </p>
              <div className="row w-100 mx-0">
                <div className="col-7 px-1">
                  <img src={Consultorio1} alt="Consultorio" />
                </div>
                <div className="col-5 px-1">
                  <img src={Consultorio2} alt="Consultorio" />
                </div>
                <div className="col-5 px-1 mt-2">
                  <img src={Consultorio3} alt="Consultorio" />
                </div>
                <div className="col-7 px-1 mt-2">
                  <img src={Consultorio4} alt="Consultorio" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Bgray>
    </>
  );
}

export default Home;
