import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Social() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar7')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar7'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: O que é fobia social e como é feito o tratamento?
        </title>
        <meta
          name="description"
          content="Você sabe o que é fobial social? A Dra. Priscila Rocco explica como identificar o transtorno e como é feito o tratamento."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Fobia Social:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    A fobia social vai muito além da timidez: é um transtorno
                    marcado pelo medo excessivo e incapacitante de situações de
                    exposição social, ou seja, situações em que a pessoa possa
                    ser avaliada ou julgada pelos outros. Isso pode ser desde
                    falar em público até pedir uma pizza por telefone.
                  </p>
                  <p className="px-5 texto-desk">
                    A fobia social vai muito além da timidez: é um transtorno
                    marcado pelo medo excessivo e incapacitante de situações de
                    exposição social, ou seja, situações em que a pessoa possa
                    ser avaliada ou julgada pelos outros. Isso pode ser desde
                    falar em público até pedir uma pizza por telefone.
                    <br />
                    <br />O paciente com esse transtorno tem pavor de parecer
                    ridículo e interpreta contratempos corriqueiros como
                    situações humilhantes e embaraçosas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                Nesses casos, quando o paciente tenta se expor, ele entra em
                pânico: sente a voz e as mãos trêmulas, fica suado, gagueja,
                fica vermelho, sente as palavras fugirem da boca e o pensamento
                em branco. É um sofrimento terrível e uma
                vergonha&nbsp;indescritível.
              </p>
              <p className="text-justify texto-desk">
                Cabe lembrar que essa é uma doença constante. Se a pessoa tem
                períodos de timidez e períodos de extroversão, o diagnóstico tem
                que ser revisto (o transtorno bipolar pode estar associado).
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                O paciente com esse transtorno tem pavor de parecer ridículo e
                interpreta contratempos corriqueiros como situações humilhantes
                e embaraçosas. Nesses casos, quando o paciente tenta se expor,
                ele entra em pânico: sente a voz e as mãos trêmulas, fica suado,
                gagueja, fica vermelho, sente as palavras fugirem da boca e o
                pensamento em branco. É um sofrimento terrível e uma vergonha
                indescritível. Cabe lembrar que essa é uma doença constante. Se
                a pessoa tem períodos de timidez e períodos de extroversão, o
                diagnóstico tem que ser revisto (o transtorno bipolar pode estar
                associado).
              </p>
              <h2 className="mostrar7 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade a fobia social é mais frequente?
              </h2>
              <p className="d-none justify">
                Em geral aparece em indivíduos mais introvertidos e, na
                infância, pode se manifestar como ataques de choro ou
                imobilidade. A adolescência é a fase em que a pessoa mais sofre
                e quando podem surgir doenças associadas como a dependência de
                álcool. É comum tentar se ‘acalmar’ e enfrentar as situações
                tomando uma dose e isso se transforma num vício.
              </p>
              <h2 className="mostrar7 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é o tratamento da fobia social?
              </h2>
              <p className="d-none justify">
                O tratamento é feito com <b>terapia de exposição</b> (tipo
                específico de psicoterapia em que o paciente vai gradualmente
                enfrentando as situações que o deixam ansioso) e a medicação é
                associada na maioria dos casos (não obrigatória). A terapia de
                exposição pode ser facilitada com a participação de animais de
                suporte emocional e os cães são mestres nisso.
                <br />
                <br />O tratamento medicamentoso é feito basicamente com
                antidepressivos, para diminuir a ansiedade e permitir que o
                paciente se exponha. O uso de benzodiazepínicos (calmantes tarja
                preta) está contraindicado porque o efeito é semelhante ao do
                álcool: desinibe na hora, mas provoca dependência, além de
                prejuízos de atenção e memória que podem perdurar mesmo depois
                da interrupção do uso. Para sintomas pontuais como tremores nas
                mãos, eventualmente podem ser usadas medicações específicas para
                cada sintoma.
              </p>
              <h2 className="mostrar7 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar o paciente com fobia social?
              </h2>
              <p className="d-none justify">
                A família ajuda o paciente permitindo que ele se exponha, ou
                seja, incentivando-o a sair, se comunicar e enfrentar a doença,
                porém sem obrigá-lo. O psiquiatra ou o psicólogo vão orientar
                sobre técnicas de exposição gradual para que o paciente vá aos
                poucos enfrentando a doença e adquirindo uma vida social normal.
                Superproteger o paciente ou se culpar pelo que ele está passando
                só atrapalha.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Social;
