import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Toc() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar5')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar5'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: TOC: Transtorno Obsessivo Compulsivo saiba o que é
        </title>
        <meta
          name="description"
          content="Saiba o que é e como identificar o TOC, transtorno obsessivo compulsivo, por Dra. Priscila Rocco."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Transtorno obsessivo compulsivo:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    O TOC ou transtorno obsessivo compulsivo é uma doença
                    caracterizada pela presença de obsessões e compulsões.
                  </p>
                  <p className="px-5 texto-desk">
                    O TOC ou transtorno obsessivo compulsivo é uma doença
                    caracterizada pela presença de obsessões e compulsões.
                    <br />
                    <br />
                    As obsessões são ideias repetitivas, intrusivas e muitas
                    vezes desagradáveis que aparecem do nada na cabeça sem que a
                    pessoa consiga evitar. Podem ser preocupações, cenas,
                    imagens, números, músicas... Vão desde uma preocupação
                    excessiva com limpeza e organização até imaginar pessoas
                    queridas num caixão. Isso ocupa muito tempo do dia do
                    paciente, desvia a atenção de coisas importantes e gera
                    muita angústia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                Já as compulsões são as ‘manias’ ou ‘rituais’, ou seja, são
                jeitos peculiares de fazer determinadas coisas para aliviar as
                obsessões ou simplesmente porque o paciente sente que “tem que
                fazer”. As compulsões podem estar diretamente relacionadas as
                obsessões, como tomar banhos escaldantes e demorados 4x por dia
                para aliviar o medo obsessivo de contaminação ou podem ser
                disparatadas como bater na madeira para evitar que um
                parente&nbsp;morra.
              </p>
              <p className="text-justify texto-desk">
                Vale lembrar que as manias ou compulsões nem sempre são
                visíveis. Existem os chamados ‘rituais mentais’, em que a mania
                está na cabeça do paciente, quem está perto nem imagina que ele
                está somando placas de carros que deem 5 para afastar a obsessão
                de que a esposa vai sumir no mundo sem deixar pistas ou que ele
                não levanta da cadeira porque são 15:59 e 59 é número ímpar. Nem
                sempre as 2 coisas ocorrem juntas. Alguns pacientes têm só
                obsessões e outros só compulsões.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                As obsessões são ideias repetitivas, intrusivas e muitas vezes
                desagradáveis que aparecem do nada na cabeça sem que a pessoa
                consiga evitar. Podem ser preocupações, cenas, imagens, números,
                músicas... Vão desde uma preocupação excessiva com limpeza e
                organização até imaginar pessoas queridas num caixão. Isso ocupa
                muito tempo do dia do paciente, desvia a atenção de coisas
                importantes e gera muita angústia. Já as compulsões são as
                ‘manias’ ou ‘rituais’, ou seja, são jeitos peculiares de fazer
                determinadas coisas para aliviar as obsessões ou simplesmente
                porque o paciente sente que “tem que fazer”. As compulsões podem
                estar diretamente relacionadas as obsessões, como tomar banhos
                escaldantes e demorados 4x por dia para aliviar o medo obsessivo
                de contaminação ou podem ser disparatadas como bater na madeira
                para evitar que um parente morra. Vale lembrar que as manias ou
                compulsões nem sempre são visíveis. Existem os chamados ‘rituais
                mentais’, em que a mania está na cabeça do paciente, quem está
                perto nem imagina que ele está somando placas de carros que deem
                5 para afastar a obsessão de que a esposa vai sumir no mundo sem
                deixar pistas ou que ele não levanta da cadeira porque são 15:59
                e 59 é número ímpar. Nem sempre as 2 coisas ocorrem juntas.
                Alguns pacientes têm só obsessões e outros só compulsões.
              </p>
              <h2 className="mostrar5 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar o transtorno obsessivo compulsivo?
              </h2>
              <p className="d-none justify">
                O diagnóstico é clínico, ou seja, durante a consulta
                psiquiátrica o paciente conta o que pensa e o que sente e o
                psiquiatra constata o problema. Geralmente os pacientes demoram
                muito a procurar ajuda porque sentem vergonha dos sintomas. O
                paciente sabe que os pensamentos e as compulsões não fazem
                sentido. O que ele não sabe é que cerca de 2,5% da população tem
                isso e que existe tratamento.
              </p>
              <h2 className="mostrar5 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em que idade o TOC é mais frequente?
              </h2>
              <p className="d-none justify">
                Geralmente na adolescência, tendendo a ser mais precoce em
                meninos (ao redor dos 10 a 12 anos de idade). Raramente começa
                depois dos 40 anos.
              </p>
              <h2 className="mostrar5 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento do TOC?
              </h2>
              <p className="d-none justify">
                O tratamento é feito com terapia cognitivo comportamental (TCC),
                orientada pelo próprio psiquiatra ou por um psicólogo e, na
                maioria dos casos, com medicação associada. Vários
                antidepressivos com ação na serotonina podem ser usados para
                tratar o TOC. A diferença do tratamento do TOC para a depressão
                são as doses e o tempo. Geralmente pacientes com transtorno
                obsessivo compulsivo precisam de doses maiores de
                antidepressivos e demoram mais tempo para apresentarem uma
                melhora dos sintomas. É importante ressaltar: demora, porém,
                melhora. E tem mais: a terapia comportamental é parte
                fundamental do tratamento.
              </p>
              <h2 className="mostrar5 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar no tratamento do TOC?
              </h2>
              <p className="d-none justify">
                Os rituais/manias de limpeza, checagem e organização estão entre
                os mais comuns, são facilmente identificáveis e são alvos de
                queixas dos familiares, que muitas vezes são obrigados pelo
                paciente a cumprir os rituais. É frequente a família mudar toda
                a rotina para se adequar aos horários dos banhos do paciente ou
                comprar produtos de limpeza a granel sem necessidade ou ser
                questionada o tempo todo sobre as mínimas coisas (rituais de
                checagem).
                <br />
                <br />A família é parte fundamental do tratamento e tem que
                estar em terapia junto com o paciente. Ambos (família e
                paciente) precisam mudar o comportamento através dos exercícios
                propostos na <b>terapia de exposição</b> para vencer a doença.
                Em tempo: a influência genética é significativa. Se você tem TOC
                pode apostar que algum parente próximo a você também tem.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Toc;
