import React from 'react';
import { Helmet } from 'react-helmet';

import { Conteudo, Bgray } from './styles';

import consulta from '~/assets/informacoes/consulta.png';
import consultaMobile from '~/assets/informacoes/consulta-mobile.png';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Priscila Rocco: psiquiatra e psicoterapeuta - Primeira consulta e
          retorno
        </title>
        <meta
          name="description"
          content="Dra. Priscila Rocco realiza consulta psiquiátrica no consultório e domiciliar, além de avaliação hospitalar e acompanhamento em casos de internação psiquiátrica."
        />
      </Helmet>
      <Conteudo>
        <div className="bg-green">
          <div className="container">
            <h1 className="text-center py-lg-5 py-4 mb-0">Primeira consulta</h1>
          </div>
        </div>
      </Conteudo>

      <Bgray>
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <img
                src={consulta}
                className="d-none d-lg-block"
                alt="Primeira Consulta"
              />
              <img
                src={consultaMobile}
                className="d-lg-none"
                alt="Primeira Consulta"
              />
              <div className="row justify-content-center">
                <div className="col-lg-10 justify">
                  <h2 className="mt-5">
                    Agora que o preconceito contra o transtorno mental foi
                    vencido, veja como o psiquiatra pode ajudá-lo.
                  </h2>
                  <p>
                    Quando você decide procurar ajuda, o primeiro (e mais
                    difícil) passo em direção ao seu bem-estar já está dado. A
                    primeira consulta significa que você perdeu, ou está pelo
                    menos disposto a perder, o preconceito quanto à figura do
                    psiquiatra e quanto a você mesmo. Significa que você se
                    respeita, que quer o melhor para si e que fará isso de forma
                    coerente.
                  </p>
                  <p>
                    Você pode vir sozinho, mas, na primeira avaliação, um
                    acompanhante (pais, amigos próximos ou cônjuge) pode ajudar
                    muito, fornecendo detalhes e impressões que muitas vezes
                    escapam ao paciente. Independentemente da presença de um
                    transtorno mental, nunca temos uma percepção completa e
                    absoluta de nós mesmos e nem de como nosso modo de agir
                    reflete em quem está a nossa volta. Lembre-se: quanto mais
                    informações você puder fornecer, mais acurado será o seu
                    diagnóstico e melhor conduzido será
                    o&nbsp;seu&nbsp;tratamento.
                  </p>
                  <p>
                    Na primeira consulta, são solicitados alguns exames gerais
                    e, dependendo das suspeitas clínicas levantadas, exames
                    específicos. É conveniente trazer todos os exames de sangue
                    feitos nos últimos 3 meses e todos os exames de imagem
                    (ressonância magnética nuclear, tomografia computadorizada,
                    PET, etc.) que tiver feito durante a vida. Traga ainda
                    prescrições antigas e os nomes de todas as medicações que
                    você está tomando regularmente. Lembre-se que medicações
                    (até aquelas feitas de plantas) podem interagir umas com as
                    outras e fazer com que uma delas perca o efeito ou mesmo
                    provoque intoxicações graves.
                  </p>
                  <h2 className="h6">
                    <b>O retorno com o psiquiatra</b>
                  </h2>
                  <p>
                    Em todas as consultas é realizado o exame psíquico, ou seja,
                    seu estado de alerta, humor, afeto, conteúdo de pensamento,
                    organização de discurso, linguagem, atenção e memória serão
                    avaliados. Seja pelo próprio curso da doença, seja pelos
                    efeitos das medicações prescritas, o quadro pode mudar muito
                    em um curto espaço de tempo e, com isso, a conduta médica
                    também pode variar. Esse tipo de avaliação demanda tempo e,
                    por essa razão, todas as consultas têm a mesma duração e são
                    cobradas independentemente do intervalo de tempo entre elas,
                    conforme artigos 2º e 3º da resolução
                    CFM&nbsp;nº&nbsp;1.958/2010.
                  </p>
                  <a href="http://www.portalmedico.org.br/resolucoes/CFM/2010/1958_2010.htm">
                    Clique aqui e leia a resolução na&nbsp;íntegra
                  </a>
                  <h2 className="h6 mt-3">
                    <b>Nunca leve dúvidas para casa</b>
                  </h2>
                  <p>
                    Pergunte tudo (e mais um pouco) para o seu médico durante as
                    consultas. Tirar dúvidas com vizinhos, amigos ou parentes só
                    vale a pena se o seu objetivo for colecionar opiniões ao
                    invés de se tratar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Bgray>
    </>
  );
}

export default Home;
