/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { FaWhatsapp, FaSortDown } from 'react-icons/fa';

import Dropdown from 'react-bootstrap/Dropdown';

import { Container } from './styles';
import Logo from '~/assets/logo.svg';
import LogoMobile from '~/assets/logo-mobile2.svg';

function Menu() {
  const location = useLocation();

  const [tipoMenu, setTipoMenu] = useState(0);
  const [tipoDropdown1, setTipoDropdown1] = useState(false);
  const [tipoDropdown2, setTipoDropdown2] = useState(false);
  useEffect(() => {
    setTipoDropdown1(false);
    setTipoDropdown2(false);
  }, [location]);
  function handleMenu(tipo) {
    setTipoMenu(tipo);
  }
  function clickDropdown(num, bool) {
    setTipoDropdown1(false);
    setTipoDropdown2(false);
    if (num === 1) {
      setTipoDropdown1(bool);
    } else {
      setTipoDropdown2(bool);
    }
  }
  return (
    <Container className="fixed-top">
      <div className="container">
        <nav className="navbar px-0 navbar-toggleable-sm navbar-expand-lg py-0">
          <div className="col-12 px-0">
            <ul className="nav navbar-nav align-items-center">
              <li className="w-sm-100-logo">
                <div className="row h-100">
                  <div className="col-md-6 col-5">
                    <div className="d-flex h-100 align-items-center">
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        <img
                          src={Logo}
                          className="py-3 logo sm-none tam-350 tam-250"
                          alt="Logo Profa Dra Priscila Rocco"
                        />
                        <img
                          src={LogoMobile}
                          className="py-3 logo md-none"
                          alt="Logo Profa Dra Priscila Rocco"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 text-right md-none">
                    <button
                      className={`${
                        tipoMenu === 2 ? 'is--active' : ''
                      } menu-btn my-3`}
                      aria-labelledby="sr-only"
                      type="button"
                      onClick={() =>
                        tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                      }
                    >
                      <span className="sr-only" id="sr-only">
                        Menu
                      </span>
                      <div className="menu-btn__icon" aria-hidden="true">
                        <span className="icon icon--menu">
                          <span />
                          <span />
                          <span />
                        </span>
                        <span className="icon icon--close">
                          <span />
                          <span />
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </li>
              <li className="py-4 ml-md-auto mr-2 nav-item sm-none">
                <Link to={`${process.env.PUBLIC_URL}/sua-medica`}>
                  Conheça sua médica
                </Link>
              </li>
              <li className="py-4 nav-item mx-3 sm-none">
                <Dropdown
                  onClick={() => clickDropdown(1, !tipoDropdown1)}
                  show={tipoDropdown1}
                >
                  <Dropdown.Toggle className="px-0">
                    Especialidades
                    <FaSortDown className="mb-2 ml-1" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/depressao`}
                    >
                      Depressão
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/transtorno-bipolar`}
                    >
                      Transtorno bipolar
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/ansiedade-generalizada`}
                    >
                      Ansiedade generalizada
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/obesidade`}
                    >
                      Obesidade
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/toc`}
                    >
                      TOC
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/sindrome-panico`}
                    >
                      Síndrome do pânico
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/fobia-social`}
                    >
                      Fobia Social
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/esquizofrenia`}
                    >
                      Esquizofrenia
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/transtorno-dismorfico`}
                    >
                      Dismorfofobia
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="py-4 nav-item mx-3 sm-none">
                <Dropdown
                  onClick={() => clickDropdown(2, !tipoDropdown2)}
                  show={tipoDropdown2}
                >
                  <Dropdown.Toggle className="px-0">
                    Informações
                    <FaSortDown className="mb-2 ml-1" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/tipos-de-atendimento`}
                    >
                      Tipos de atendimento
                    </Link>

                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/primeira-consulta`}
                    >
                      Primeira consulta
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/quando-procurar-psiquiatra`}
                    >
                      Quando procurar
                      <br /> um psiquiatra
                    </Link>
                    <Link
                      className="dropdown-item text-justify"
                      role="button"
                      to={`${process.env.PUBLIC_URL}/contato`}
                    >
                      Contato
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="py-4 nav-item mx-3 sm-none">
                <Link to={`${process.env.PUBLIC_URL}/consultorio`}>
                  Consultório
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={` ${
            // eslint-disable-next-line no-nested-ternary
            tipoMenu === 0
              ? 'd-none'
              : tipoMenu === 1
              ? 'slide-out-left d-none'
              : 'slide-in-left vh-100'
          } md-none n-w-overflow`}
        >
          <ul>
            <li className="py-1 text-center mt-4">
              <Link
                to={`${process.env.PUBLIC_URL}/sua-medica`}
                onClick={() => (tipoMenu === 2 ? handleMenu(1) : handleMenu(2))}
              >
                Conheça sua médica
              </Link>
            </li>
            <li className="py-0 nav-item mx-2 text-center">
              <Dropdown>
                <Dropdown.Toggle>Especialidades</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/depressao`}
                  >
                    Depressão
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/transtorno-bipolar`}
                  >
                    Transtorno bipolar
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/ansiedade-generalizada`}
                  >
                    Ansiedade generalizada
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/obesidade`}
                  >
                    Obesidade
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/toc`}
                  >
                    TOC
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/sindrome-panico`}
                  >
                    Síndrome do pânico
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/fobia-social`}
                  >
                    Fobia Social
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/esquizofrenia`}
                  >
                    Esquizofrenia
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/transtorno-dismorfico`}
                  >
                    Dismorfofobia
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="py-0 nav-item mx-2 text-center">
              <Dropdown>
                <Dropdown.Toggle>Informações</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/tipos-de-atendimento`}
                  >
                    Tipos de atendimento
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/primeira-consulta`}
                  >
                    Primeira consulta
                  </Link>
                  <Link
                    className="dropdown-item"
                    role="button"
                    onClick={() =>
                      tipoMenu === 2 ? handleMenu(1) : handleMenu(2)
                    }
                    to={`${process.env.PUBLIC_URL}/quando-procurar-psiquiatra`}
                  >
                    Quando procurar
                    <br /> um psiquiatra
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="py-1 text-center">
              <Link
                to={`${process.env.PUBLIC_URL}/consultorio`}
                onClick={() => (tipoMenu === 2 ? handleMenu(1) : handleMenu(2))}
              >
                Consultório
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send/?phone=5511940317994&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es&type=phone_number&app_absent=0"
        target="blank"
      >
        <div className="btn-wpp">
          <FaWhatsapp size={40} />
        </div>
      </a>
    </Container>
  );
}

export default Menu;
