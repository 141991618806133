import React from 'react';
import PoliticaPrivacidadeComponent from '~/components/PoliticaPrivacidade';

export default function PoliticaPrivacidade() {
  return (
    <PoliticaPrivacidadeComponent
      nome="Dra. Profa. Priscila Rocco"
      email="contato@drapriscilarocco.com.br"
      site="http://www.drapriscilarocco.com.br/"
      cnpj="26.216.471/0001-25"
      endereco="R. Alves Guimarães, 462 - cj. 43 - Pinheiros -
      São Paulo - SP, 05410-000"
      razaoSocial="Dra Priscila Clínica Médica EIRELI"
      plataforma="Google"
    />
  );
}
