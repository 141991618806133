/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '~/pages/Home/index';
import Depressao from '~/pages/Depressao/index';
import Bipolar from '~/pages/Bipolar/index';
import Ansiedade from '~/pages/Ansiedade/index';
import Obesidade from '~/pages/Obesidade/index';
import Toc from '~/pages/Toc/index';
import Panico from '~/pages/Panico/index';
import Social from '~/pages/Social/index';
import Esquizofrenia from '~/pages/Esquizofrenia/index';
import Dismorfico from '~/pages/Dismorfico/index';
import Medica from '~/pages/Medica/index';
import Atendimento from '~/pages/Atendimento/index';
import Consulta from '~/pages/Consulta/index';
import Psiquiatra from '~/pages/Psiquiatra/index';
import Consultorio from '~/pages/Consultorio/index';
import Contato from '~/pages/Contato/index';
import PoliticaPrivacidade from '~/pages/PoliticaPrivacidade/index';

// import Page404 from '~/pages/Page404/';

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route path={`${process.env.PUBLIC_URL}/politica-privacidade`} exact component={PoliticaPrivacidade} />

      <Route path={`${process.env.PUBLIC_URL}/depressao`} exact component={Depressao} />
      <Route path={`${process.env.PUBLIC_URL}/transtorno-bipolar`} exact component={Bipolar} />
      <Route path={`${process.env.PUBLIC_URL}/ansiedade-generalizada`} exact component={Ansiedade} />
      <Route path={`${process.env.PUBLIC_URL}/obesidade`} exact component={Obesidade} />
      <Route path={`${process.env.PUBLIC_URL}/toc`} exact component={Toc} />
      <Route path={`${process.env.PUBLIC_URL}/sindrome-panico`} exact component={Panico} />
      <Route path={`${process.env.PUBLIC_URL}/fobia-social`} exact component={Social} />
      <Route path={`${process.env.PUBLIC_URL}/esquizofrenia`} exact component={Esquizofrenia} />
      <Route path={`${process.env.PUBLIC_URL}/transtorno-dismorfico`} exact component={Dismorfico} />
      <Route path={`${process.env.PUBLIC_URL}/sua-medica`} exact component={Medica} />
      <Route path={`${process.env.PUBLIC_URL}/tipos-de-atendimento`} exact component={Atendimento} />
      <Route path={`${process.env.PUBLIC_URL}/primeira-consulta`} exact component={Consulta} />
      <Route path={`${process.env.PUBLIC_URL}/quando-procurar-psiquiatra`} exact component={Psiquiatra} />
      <Route path={`${process.env.PUBLIC_URL}/consultorio`} exact component={Consultorio} />
      <Route path={`${process.env.PUBLIC_URL}/contato`} exact component={Contato} />

      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Page404} /> */}
    </Switch>
  );
}
