import styled from 'styled-components';
import banner from '~/assets/especialidades/depressao.png';
import bannerMobile from '~/assets/especialidades/depressaoMobile.png';

export const Container = styled.div`
  .banner {
    background-image: url(${banner});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg-gray {
    background-color: #ffffff;
    padding: 12% 0;
    border-bottom-right-radius: 370px;
  }
  .texto-mobile {
    display: none;
  }

  h1 {
    color: #5a853d;
    font-family: 'Times New Roman';
  }
  p {
    font-size: 1.3rem;
    line-height: 25px;
  }
  @media (max-width: 1366px) {
    .bg-gray {
      padding: 8% 0;
    }
    h1 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 1441px) {
    .banner {
      margin-top: 84px;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
    .texto-mobile {
      display: block;
      font-size: 1.2rem;
    }
    .texto-desk {
      display: none;
    }
    .banner {
      background-image: url(${bannerMobile});
      background-position: bottom;
      height: 100vh;
    }
    .bg-gray {
      border-bottom-right-radius: 210px;
      padding: 8% 0 12% 0;
    }
  }
`;
export const Conteudo = styled.div`
  h2 {
    color: #5a853d;
    padding-bottom: 2rem;
    font-weight: bold;
    font-size: 1.7rem;
    cursor: pointer;
    display: flex;
    .seta {
      margin: auto 1rem auto 0;
    }
  }
  .texto-mobile {
    display: none;
  }
  p {
    font-size: 1.4rem;
    padding-bottom: 2rem;
    line-height: 25px;
  }
  @media (max-width: 1441px) {
    h2 {
      font-size: 1.1rem;
    }
    p {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    .texto-mobile {
      display: block;
      font-size: 1.2rem;
    }
    .texto-desk {
      display: none;
    }
    h2 {
      padding: 0 1rem;
      margin: 1.8rem 0;
    }
    p {
      font-size: 1.2rem;
      padding: 0 1rem;
    }
  }
  @media screen and (min-width: 769px) {
    .justify {
      text-align: justify;
    }
  }
`;
