import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Bio, Bgray, Publicacoes, Contato } from './styles';

import Banner from '~/assets/home/banner.png';
import Banner2 from '~/assets/medica/2.png';
import Banner2Mobile from '~/assets/medica/2Mobile.png';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sobre Priscila Rocco, doutora em psiquiatria e psicoterapeuta
        </title>
        <meta
          name="description"
          content="Conheça mais sobre a Dra. Priscila Rocco, doutora em medicina pela USP."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 p-0">
              <img src={Banner} alt="banner Profa Dra Priscila Rocco" />
            </div>
            <div className="col-md-6 px-4 ml-2 ml-md-0 px-md-4">
              <div className="row align-items-center h-100">
                <div className="col-12 col-md-6  mt-3 mt-lg-0">
                  <h1 className="display-2 font-times title">
                    <span className="h1 h1-small font-times">
                      Prof<sup className="h4 h4-small font-times">a </sup>Dr
                      <sup className="h4 h4-small font-times">a </sup>
                    </span>
                    <br />
                    Priscila Rocco
                  </h1>
                  <p>
                    CRM (SP): 100.765
                    <br />
                    RQE 24890
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Bio>
        <div className="container">
          <div className="row">
            <div className="col justify">
              <p>
                Nasci em 06/08/1976 (pois é: não escondo minha idade) em São
                Bernardo do Campo. Meu pai é advogado e minha mãe, catequista.
                Sou a quarta filha orgulhosa desse casal que já está junto há
                mais de 50 anos. Ao todo somos cinco irmãos e eu fui a única a
                escolher Medicina. Quer saber por que?
              </p>
              <p>
                Bem, é difícil explicar essas vontades de terceiro colegial (era
                como chamavam o ensino médio na minha época). Tem gente que
                sempre quis ser médico e, quando chega lá, se desencanta... Eu
                tenho o prazer de dizer que simplesmente cheguei lá e me
                apaixonei. Não me envergonho de dizer que caí de paraquedas no
                lugar exato: Psiquiatria é a minha vida, minha escola diária
                onde tenho grandes mestres: meus pacientes. Cada caso é único e
                peculiar a seu modo e eu sempre aprendo.
              </p>
              <p className="mb-4">
                Essa jornada é cheia de pessoas incríveis: meu maridão, meus
                amigos (que graças a Deus são muitos), colegas e o pessoal da
                igreja ortodoxa antioquina (eu cuido do corpo, da mente e do
                espírito e recomendo a você que faça o mesmo). Eu me sinto
                verdadeiramente grata pela vida que tenho e espero que eu possa
                continuar dividindo essa história com você por muito tempo.
                <br />
                <br /> <span className="ml-5">Um grande abraço!</span>
              </p>
            </div>
          </div>
        </div>
      </Bio>
      <Bgray>
        <div className="container py-lg-5 py-4">
          <div className="row w-100 m-0">
            <div className="col-md-6">
              <h2 className="mb-lg-5 mb-4">Formação profissional</h2>
              <p className="marcador position-relative mt-lg-5 mr-lg-5 ">
                Doutorado em Ciências Médicas pela Faculdade de Medicina da USP.
              </p>
              <p className="marcador position-relative  mt-lg-5 mr-lg-5 ">
                Residência médica em Psiquiatria no Instituto de Psiquiatria do
                Hospital das Clínicas da Faculdade de Medicina da USP.
              </p>
              <p className="marcador position-relative mt-lg-5 mr-lg-5 ">
                Graduação em Medicina pela Faculdade de Medicina da USP.
              </p>
            </div>
            <div className="col-md-6">
              <h2 className="mb-lg-5 mb-4">Títulos, cargos e filiações</h2>
              <p className="marcador position-relative mt-lg-5 mr-lg-5 ">
                Professora adjunta de Saúde Mental na Faculdade de Medicina de Jundiaí
              </p>
              <p className="marcador position-relative mt-lg-5 mr-lg-5 ">
                Médica do Núcleo de Obesidade e Transtornos Alimentares do
                Hospital Sírio-Libanês.
              </p>
              <p className="marcador position-relative mt-lg-5 mr-lg-5 ">
                Médica interconsultora nos Hospitais Samaritano e Santa
                Catarina.
              </p>
              <p className="marcador position-relative mt-lg-5 mr-lg-5">
                Título de especialista em Psiquiatria pela Associação Brasileira
                de Psiquiatria.
              </p>
              <p className="marcador position-relative mt-lg-5 mr-lg-5">
                Membro da Associação Brasileira de Psiquiatria.
              </p>
            </div>
          </div>
        </div>
      </Bgray>
      <Publicacoes className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-4">
              <h2>Currículo completo disponível na plataforma lattes:</h2>
              <a
                href="http://lattes.cnpq.br/8973639256419446
"
              >
                http://lattes.cnpq.br/8973639256419446
              </a>
            </div>
            <div className="col-12 pb-4">
              <h2>Publicações</h2>
            </div>
            <div className="col-md-6">
              <p>
                Psicoestimulantes. In: Ivan Aprahamian, Marina Maria Biella,
                Marcus Kiiti Borges e Salma Rose Imanara Ribeiz. Depressão: Guia
                prático. 1.ed. Manole 2020, p. 369-374.
                <br /> <br />
                Work-Family Conflict and Ideal Cardiovascular Health Score in
                the ELSA-Brasil Baseline Assessment. Journal of the American
                Heart Association 2019 Oct 15;8(20):e012701 (autora) [PMID:
                31597505]
                <br /> <br />
                Job Strain and Cardiovascular Health Score (from the Brazilian
                Longitudinal Study of Adult Health [ELSA-Brasil] Baseline). The
                American Journal of Cardiology 2017 Jul 15;120(2):207-212
                (autora) [PMID: 28532767]
                <br /> <br />
                Relation of Anxiety and Depressive Symptoms to Coronary Artery
                Calcium (From the ELSA-Brasil Baseline Data). The American
                Journal of Cardiology,, 2016 Jul 15;118(2):183-7. (co-autora).
                [PMID: 27236256]
                <br /> <br />
                Manejo de emergências relacionadas ao uso de álcool e outras
                substâncias. In: Eduardo de Castro Humes; Márcio Eduardo
                Bergamini Vieira; Renério Fráguas Júnior. (Org.). Psiquiatria
                Interdisciplinar. 1ed.: Manole, 2016, p. 181-191
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Psicofarmacologia dos Transtornos Depressivos. In: Chei-Tung
                Teng; Frederico Navas Demétrio. (Org.). Psicofarmacologia
                Aplicada: manejo prático dos transtornos mentais. 2ed.: Atheneu,
                2011, p. 113-158.
                <br />
                <br />
                Transtornos Psiquiátricos em Familiares, Crianças e Adolescentes
                que Convivem com a Dependência Química em seus Lares. In: Figlie
                NB; Milagres E; Crowe J... (Org.). Família e Dependência
                Química: uma experiência de prevenção com crianças e
                adolescentes no Jardim Ângela. 1ed.São Paulo: Roca, 2009, p.
                131-151.
                <br />
                <br />
                Antidepressivos. In: Neto MRL; Elkis H... (Org.). Psiquiatria
                Básica. 2ed.São Paulo: Artmed, 2007, p. 547-561.
                <br />
                <br />
                Infanticídio. In: Serafim AP; Barros DM; Rigonatti, SP. Temas em
                Psiquiatria Forense e Psicologia Jurídica. São Paulo: Editora
                Vetor, 2006, p. 53-76
                <br />
                <br />
                Avaliação de Risco: comportamento suicida. In: Abreu CN, Salzano
                FT, Vasques F, Cangelli Filho R, Cordás T (org). Síndromes
                Psiquiátricas: diagnóstico e entrevista para profissionais de
                saúde mental. São Paulo. Artmed, 2006, p. 31-36.
              </p>
            </div>
          </div>
        </div>
      </Publicacoes>
      <Contato>
        <div className="row flex-row-reverse w-100 mx-0">
          <div className="col-12 col-md-7 px-0">
            <img src={Banner2} alt="Contato" className="mobile-none" />
            <img
              src={Banner2Mobile}
              alt="Contato"
              className="desk-none w-100"
            />
          </div>
          <div className="col-md-4 my-md-4">
            <div className="d-flex align-items-center h-100">
              <div className="w-100-mobile text-center-mobile pb-4 pb-md-0">
                <h3 className="mb-4 psicoterapia">
                  Psiquiatria e Psicoterapia se complementam
                  na&nbsp;atenção&nbsp;à&nbsp;saúde&nbsp;mental.
                </h3>
                <Link
                  to={`${process.env.PUBLIC_URL}/contato`}
                  className="mt-3 font-weight-bold"
                >
                  Entre em contato
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Contato>
    </>
  );
}

export default Home;
