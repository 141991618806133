import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 80px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-size: ${props => props.fontSize};
  background-color: ${props => props.bgColor};

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1650px) {
    max-width: 1364px;
  }
`;

export const Content = styled.div`
  font-size: 1em;

  h1 {
    margin: 100px 0 10px;
    text-align: center;
    font-size: 3.5em;
    color: ${props => props.titleColor};
  }

  > div {
    font-size: 1.2em;
    background-color: #00000008;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: ${props => props.bgColor};

    > div {
      font-size: 1em;

      ul {
        list-style: disc;
        margin-left: 20px;
      }

      ol {
        list-style: none;
      }

      p {
        font-size: 1.7em;
        color: ${props => props.textColor};

        b {
          font-size: 1.1em;
          color: ${props => props.textColor};
        }
      }

      + div {
        margin-top: 20px;
      }
    }
  }
`;
