import styled from 'styled-components';

export const Container = styled.div`
  .tam-350 {
    width: 350px;
  }
  .bg-footer {
    background-color: #415c2f;
  }
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  h3,
  p {
    color: #fff !important;
  }
  p {
    font-size: 1.2rem;
    font-weight: 200;
  }
  h3 {
    font-weight: 200;
    font-size: 1.2rem;
  }
  .crm {
    font-size: 0.8rem;
  }
  .prof {
    font-size: 2.5rem;
    font-weight: 300;
  }

  .prof-dra {
    font-size: 1.9rem;
  }

  .a-sup {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 768px) {
    .prof {
      justify-content: center !important;
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 1366px) {
    p {
      font-size: 1rem;
    }
    h3 {
      font-size: 1.2rem;
    }
  }
`;
