import styled from 'styled-components';

export const Conteudo = styled.div`
  .bg-green {
    background-color: #415c2f;
    margin-top: 4.5%;
    h1 {
      color: #fff;
      font-weight: lighter;
      font-size: 2rem;
      font-family: 'Times New Roman';
    }
  }
  @media screen and (max-width: 1441px) {
    .bg-green {
      margin-top: 84px;
    }
  }
  @media screen and (max-width: 768px) {
    .bg-green {
      margin-top: 77px;
    }
    h1 {
      font-size: 1.5rem !important;
    }
  }
`;
export const Bgray = styled.div`
  background-color: #f6f6f6;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  h2 {
    font-size: 1.3rem;
    font-weight: bold;
  }
  p {
    font-size: 1.2rem;
  }
  a {
    color: #5a853d !important;
    text-decoration: underline;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  @media screen and (min-width: 769px) {
    .justify {
      text-align: justify;
    }
  }
`;
