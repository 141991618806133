import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Home() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar1')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar1'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: O que é depressão? Conheça os tipos, sintomas e
          tratamentos{' '}
        </title>
        <meta
          name="description"
          content="Você sabe o que é depressão? Nesse texto, a Dra. Priscila Rocco explica quais são os tipos, os sintomas e os tratamentos para essa doença."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Depressão:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className=" px-5 texto-mobile">
                    A depressão é uma doença diferente do sentimento de
                    tristeza, embora o paciente possa se sentir triste.
                  </p>
                  <p className="px-5 texto-desk">
                    A depressão é uma doença diferente do sentimento de
                    tristeza, embora o paciente possa se sentir triste. Ela é
                    caracterizada por um período de pelo menos 2 semanas em que,
                    na maior parte dos dias, o paciente apresenta ao mesmo tempo
                    pelo menos cinco desses sintomas: humor deprimido ou
                    irritável, perda do prazer e do interesse em mais de um
                    aspecto da vida (pessoal, profissional, afetivo), sensação
                    de pouca energia, podendo se sentir lentificado, com
                    alteração de sono e apetite (tanto para mais quanto para
                    menos), prejuízo da capacidade de prestar atenção e de
                    memorizar coisas, sentimentos de culpa exagerada e com baixa
                    autoestima.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                Em quadros mais graves, o paciente pode apresentar ideias de
                suicídio, delírios de ruína ou de culpa, como, por exemplo,
                acreditar que é o responsável por catástrofes mundiais ou que
                está em vias de falir, quando isso nitidamente não condiz com a
                realidade.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Ela é caracterizada por um período de pelo menos 2 semanas em
                que, na maior parte dos dias, o paciente apresenta ao mesmo
                tempo pelo menos cinco desses sintomas: humor deprimido ou
                irritável, perda do prazer e do interesse em mais de um aspecto
                da vida (pessoal, profissional, afetivo), sensação de pouca
                energia, podendo se sentir lentificado, com alteração de sono e
                apetite (tanto para mais quanto para menos), prejuízo da
                capacidade de prestar atenção e de memorizar coisas, sentimentos
                de culpa exagerada e com baixa autoestima. Em quadros mais
                graves, o paciente pode apresentar ideias de suicídio, delírios
                de ruína ou de culpa, como por exemplo acreditar que é o
                responsável por catástrofes mundiais ou que está em vias de
                falir, quando isso nitidamente não condiz com a realidade.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Como
                identificar a depressão?
              </h2>
              <p className="d-none justify">
                O diagnóstico é feito através de uma entrevista clínica. Na
                primeira consulta são solicitados exames para excluir as
                chamadas causas orgânicas (como problemas na tireoide, anemia e
                até mesmo AVC (“derrame”) em pacientes idosos), mas o
                diagnóstico da depressão em si não é feito com base em nenhum
                exame de laboratório ou de imagem.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Existem
                tipos de depressão?
              </h2>
              <p className="d-none justify">
                Sim. Eles se referem à gravidade e ao período em que acontecem:
                podem ser leves, moderadas ou graves, com ou sem psicose
                (delírios e alucinações), com ou sem ideação suicida,
                relacionadas às estações do ano (chamada depressão sazonal, que
                ocorre no inverno quando a incidência solar é menor e por isso
                não é frequente em países tropicais) e as que ocorrem no período
                <b> pós-parto</b>.
                <br />
                <br />
                Antigamente falava-se em <b> Distimia</b>. Embora esse termo já
                esteja em desuso, ele é sinônimo de
                <b> depressão leve crônica</b> (duração maior de 2 anos) e os
                critérios diagnósticos e o tratamento são similares ao da
                depressão, exceto pelo fato de não retirarmos o
                <b> antidepressivo</b> devido à cronicidade do quadro.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Em qual
                idade a depressão é mais frequente?
              </h2>
              <p className="d-none justify">
                A depressão pode aparecer em todas as idades, mas a média de
                maior incidência é entre 25 e 30 anos de idade. É uma das
                doenças psiquiátricas mais frequentes e acometem cerca de 15% da
                população ao longo da vida.
                <br />
                <br />
                As mulheres são as mais afetadas (2 a 3 vezes mais que os
                homens) e os períodos de pós-parto e menopausa são de alto risco
                para o desenvolvimento dessa doença.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Depressão
                é uma doença genética?
              </h2>
              <p className="d-none justify">
                Existem vários genes de vulnerabilidade (mais chance de
                desenvolver a doença, mas que não ‘causam’ a doença). Ou seja,
                são vários genes que podem ou não se manifestar e estarem
                associados à depressão. É frequente ter mais de um deprimido na
                família.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta  " /> Como é
                feito o tratamento da depressão?
              </h2>
              <p className="d-none justify">
                O tratamento da depressão é realizado de acordo com o quadro.
                Quadros leves podem ser tratados só com psicoterapia e os
                quadros moderados e graves exigem medicação além da terapia. O{' '}
                <b>tratamento medicamentoso</b> demora pelo menos 1 ano e meio e
                funciona da seguinte maneira: o antidepressivo é iniciado e o
                paciente é reavaliado dentro de 4 a 6 semanas.
                <br />
                <br />O <b>efeito antidepressivo</b> varia com o medicamento e
                só aparece entre a segunda e terceira semana de uso do remédio
                embora efeitos colaterais leves como enjoo e falta de apetite
                possam aparecer nos primeiros dias.
                <br />
                <br />
                O efeito completo aparece em 4 a 8 semanas. Na reavaliação, a
                dose é ajustada e pode variar muito dependendo do tipo de
                remédio e do metabolismo do paciente, que vai sendo reavaliado a
                cada 4 a 6 semanas até que os sintomas desapareçam.
                <br />
                <br />
                Quando o paciente fica sem sintomas, a medicação ou a combinação
                de remédios é mantida por 1 ano (fase de manutenção) para evitar
                que o paciente tenha recaídas. Depois disso, o remédio é
                retirado gradualmente e a velocidade de retirada também varia
                com a medicação, a dose e o metabolismo do paciente. Tudo é
                cuidadosamente avaliado. Quando a depressão é crônica ou
                recorrente geralmente não é possível retirar a medicação.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Antidepressivo é calmante?
              </h2>
              <p className="d-none justify">
                Não! Os chamados calmantes são aqueles tarja preta (diazepam,
                clonazepam, alprazolam...). A tarja preta significa que são
                remédios que podem viciar, inclusive porque tem efeito imediato.
                Ninguém usaria drogas para ficar relaxado ou dormir
                profundamente depois de 2 a 3 semanas.
                <br />
                Quando o paciente tem uma depressão com perfil mais irritável,
                ele de fato se sente mais calmo com o antidepressivo, mas são
                remédios diferentes, que mexem em substâncias diferentes no
                cérebro. Enquanto os ‘calmantes’ e o álcool mexem no GABA (ácido
                gama-amino butírico), os antidepressivos atuam basicamente na
                serotonina, noradrenalina e dopamina (alguns agem na melatonina
                e no glutamato, mas essa já é outra história).
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Como posso
                saber qual antidepressivo é melhor para mim?
              </h2>
              <p className="d-none justify">
                A escolha da medicação varia com o perfil de sintomas do
                paciente. Não podemos tratar do mesmo modo pacientes que têm
                diminuição do apetite e pacientes que não conseguem parar de
                comer, por exemplo. Existem várias famílias de antidepressivos
                (inibidores seletivos de receptação de serotonina, inibidores da
                receptação de dopamina e noradrenalina, duais, serotoninérgicos
                e noradrenérgicos específicos, tricíclicos...) e eles podem ser
                usados sozinhos ou combinados entre si ou com outras classes de
                medicamentos como hormônio da tireoide e antipsicóticos. Ou
                seja: não adianta dar o mesmo remédio para todo mundo e ver o
                que acontece. Aliás, tem tantos disponíveis que não há
                justificativa para isso.
                <br />
                <br />
                Existem testes genéticos que avaliam o perfil metabólico do
                paciente e, a partir daí, nos permitem avaliar quais
                medicamentos teriam menos efeitos colaterais e quais poderiam
                intoxicar o paciente. Alguns genes podem nos dar indícios de boa
                resposta, mas as evidências nesse sentido ainda são pequenas.
                <br />
                <br />
                Testes genéticos são confiáveis para analisar como o fígado do
                paciente trabalha com alguns remédios, mas os genes responsáveis
                por uma boa resposta a esse ou aquele medicamento ainda estão em
                estudo. Ou seja: não é um exame obrigatório no tratamento da
                depressão e não dá certeza sobre qual remédio irá funcionar para
                aquele paciente.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Como a
                família pode ajudar no tratamento da depressão?
              </h2>
              <p className="d-none justify">
                Compreensão é a palavra-chave. A família não deve rotular o
                paciente, mas entender que ele está doente. Ainda que essa
                doença não se veja nem se meça com exames, ela existe, tem nome
                e tratamento. E o tratamento é médico e psicológico. Apenas
                “força de vontade” não basta. Ou existe alguém que se cure de
                asma com força de vontade? Com depressão não é diferente.
                Depressão não é frescura, nem uma escolha de vida.
                <br />
                <br />É preciso encorajar o paciente a se tratar e a reagir,
                oferecendo apoio e companhia. É importante se colocar à
                disposição para ouvir o paciente, porém é proibido forçá-lo a
                falar. A família pode convidá-lo para sair e incentivar
                atividades prazerosas, mas nunca obrigá-lo a fazer algo que,
                naquele momento, ele se sente incapaz ou que está fora da sua
                cultura, como mudar de religião, por exemplo. Respeito pelos
                valores do paciente é fundamental.
              </p>
              <h2 className="mostrar1 justify">
                <img src={setaClosed} alt="seta" className="seta " /> Tenho
                depressão. Posso engravidar?
              </h2>
              <p className="d-none justify">
                Sim! Mas nem todos os antidepressivos são seguros na gestação e
                na fase de amamentação. Como o tratamento demora e a gestação e
                o pós-parto são fases muito propensas à depressão, avise seu
                médico se você pretende engravidar.
                <br />
                <br />A maternidade é algo muito especial para a maioria das
                mulheres, que devem estar em plenas condições de curtir cada
                segundo dessa experiência. Não deixe de se tratar para
                engravidar porque passar por algo tão especial quanto a gravidez
                estando triste, deprimida e sem prazer em nada não vai fazer bem
                para você, para o casal e muito menos para o bebê.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Home;
