import React from 'react';
import { Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import Routes from './routes';
import history from './services/history';

import ScrollToTop from '~/components/ScrollToTop';
import Menu from '~/components/Menu';
import Footer from '~/components/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <HttpsRedirect>
        <Router basename="/public_html/" history={history}>
          <ScrollToTop>
            <GlobalStyle />
            <Menu />
            <Routes />
            <Footer />
          </ScrollToTop>
        </Router>
      </HttpsRedirect>
    </>
  );
}

export default App;
