import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Ansiedade() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar3')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar3'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: Ansiedade: sintomas e tratamento para o transtorno de
          ansiedade generalizada
        </title>
        <meta
          name="description"
          content="A Dra. Priscila explica quais os sintomas, causas e tratamentos para o transtorno de ansiedade generalizada."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Ansiedade generalizada:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    Mas o que é transtorno de ansiedade generalizada? É mais que
                    estar ‘ligado no <span>220 V</span>’.
                  </p>
                  <p className="px-5 texto-desk">
                    Mas o que é transtorno de ansiedade generalizada? É mais que
                    estar ‘ligado&nbsp;no&nbsp;220&nbsp;V’.
                    <br /> Nesse transtorno, o paciente se mostra num estado de
                    hiperalerta: está sempre sobressaltado e preocupado.
                    <br /> As preocupações são as mais variadas e abarcam
                    inúmeros aspectos da vida do paciente, sendo
                    desproporcionais tanto em quantidade (o paciente se preocupa
                    com tudo e com todos) quanto em intensidade (o telefone toca
                    e o paciente já pensa que alguém morreu, por exemplo).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                Além disso, a pessoa também apresenta sintomas físicos
                decorrentes da ansiedade como, por exemplo, tensão e dores
                musculares, cefaleia, boca seca, palpitações, sensação de
                respiração curta, polaciúria (vontade de urinar a toda hora) e
                diarreia.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Nesse transtorno, o paciente se mostra num estado de
                hiperalerta: está sempre sobressaltado e preocupado. As
                preocupações são as mais variadas e abarcam inúmeros aspectos da
                vida do paciente, sendo desproporcionais tanto em quantidade (o
                paciente se preocupa com tudo e com todos) quanto em intensidade
                (o telefone toca e o paciente já pensa que alguém morreu, por
                exemplo). Além disso, a pessoa também apresenta sintomas físicos
                decorrentes da ansiedade como por exemplo tensão e dores
                musculares, cefaleia, boca seca, palpitações, sensação de
                respiração curta, polaciúria (vontade de urinar a toda hora) e
                diarreia.
              </p>
              <h2 className="mostrar3 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar os sintomas do transtorno de ansiedade
                generalizada?
              </h2>
              <p className="d-none justify">
                O diagnóstico é feito na consulta psiquiátrica com base no que o
                paciente diz e em como ele se comporta. Como nos demais quadros
                ansiosos, o paciente tende a estar doente desde muito jovem e
                acha que esse é ‘seu jeito de ser’. Além disso, é comum que ele
                tenha parentes muito parecidos com ele. Muitas vezes são os
                amigos ou o cônjuge que trazem o paciente para a realidade e
                apontam o quão descabidas são aquelas preocupações. Vir
                acompanhado para a consulta pode ser uma ótima ideia.
                <br />
                Cabe lembrar que os sintomas não são decorrentes do uso de
                drogas (abuso de cafeína e cocaína) e os exames são para excluir
                outras causas como hipertireoidismo, por exemplo.
              </p>
              <h2 className="mostrar3 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade o transtorno de ansiedade generalizada é mais
                frequente?
              </h2>
              <p className="d-none justify">
                Pode começar na infância onde o principal sintoma é uma
                preocupação excessiva com o desempenho escolar e com a aprovação
                dos pais. Crianças maiores e adolescentes tendem a relatar mais
                sintomas físicos.
              </p>
              <h2 className="mostrar3 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento da ansiedade generalizada?
              </h2>
              <p className="d-none justify">
                O tratamento medicamentoso é feito com antidepressivos,
                preferencialmente aqueles que têm ação simultânea na serotonina
                e na norepinefrina. Podem ser usados outros tipos de medicação
                como remédios para epilepsia, mas não se usa benzodiazepínicos
                (“calmante tarja-preta” tipo clonazepam, alprazolam, etc.)
                isoladamente porque para esses pacientes, o risco de dependência
                é enorme. Além disso, essas medicações provocam prejuízo de
                atenção e memória que podem perdurar mesmo depois que o remédio
                foi suspenso.
                <br />
                <br />A ideia não é usar medicação para sempre, mas o tratamento
                não dura menos de 1 ano porque é necessário ajustar a dose e dar
                tempo para o paciente se desenvolver na psicoterapia.
              </p>
              <h2 className="mostrar3 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar o paciente ansioso?
              </h2>
              <p className="d-none justify">
                O paciente ansioso geralmente está assim desde muito jovem e tem
                dificuldade em perceber o quanto isso o atrapalha. A família
                ajuda na medida em que percebe que essa é uma doença que tem
                tratamento e não simplesmente um ‘jeito de ser’. Pontuar o
                quanto essa ansiedade está atrapalhando o relacionamento, além
                de ajudar o paciente a vencer o preconceito e procurar
                tratamento, é uma ótima maneira de ajudar.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Ansiedade;
