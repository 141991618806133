import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Bipolar() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar2')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar2'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Especialidades: O que é o transtorno bipolar?</title>
        <meta
          name="description"
          content="Mas afinal, o que é o transtorno bipolar? A Dra. Priscila fala sobre os tipos de bipolaridade, os tratamentos e os principais sintomas."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Transtorno bipolar:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    O Transtorno Bipolar do Humor (antigamente chamado de
                    Psicose Maníaco-Depressiva) é caracterizado por fases de
                    depressão e fases de euforia (ou mania) intercaladas com
                    períodos de eutimia (i.e. sem depressão nem euforia).
                  </p>
                  <p className="px-5 texto-desk">
                    O Transtorno Bipolar do Humor (antigamente chamado de
                    Psicose Maníaco-Depressiva) é caracterizado por fases de
                    depressão e fases de euforia (ou mania) intercaladas com
                    períodos de eutimia (i.e. sem depressão nem euforia). O
                    termo mania é usado como sinônimo de euforia e não para
                    descrever comportamentos compulsivos como ‘mania de limpeza’
                    em quadros ansiosos. As fases de depressão são períodos de
                    pelo menos 2 semanas em que o paciente se sente triste,
                    deprimido ou irritadiço, sem conseguir sentir prazer com o
                    que antes era agradável, muito cansado, sem energia e com a
                    sensação de raciocínio lento.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                Já as fases de euforia ou mania podem durar poucos dias, mas a
                pessoa se sente literalmente eufórica e acelerada, com muita
                energia, precisando dormir menos que o habitual e sentindo como
                se os pensamentos fugissem&nbsp;da&nbsp;cabeça.
              </p>
              <p className="text-justify texto-desk">
                Em alguns casos, a pessoa pode apresentar delírios (crenças que
                não podem ser compartilhadas) de grandeza como o de se achar
                mais rica do que é e gastar grandes somas de dinheiro ou mesmo
                de minimizar riscos, se envolvendo em atividades perigosas como
                dirigir em alta velocidade ou experimentar drogas&nbsp;ilícitas.
              </p>
              <p className="text-justify texto-desk">
                Existem ainda os chamados estados mistos. Nessas fases, há uma
                mistura de sintomas depressivos e maníacos e a descrição mais
                clássica é a de um ‘estado de ira’: a pessoa fica tão irritada e
                briguenta que parece estar irada. Nessa fase é comum o suicídio
                (a pessoa fica com a energia da mania e o pensamento da
                depressão, uma combinação perigosa).
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                O termo mania é usado como sinônimo de euforia e não para
                descrever comportamentos compulsivos como ‘mania de limpeza’ em
                quadros ansiosos. As fases de depressão são períodos de pelo
                menos 2 semanas em que o paciente se sente triste, deprimido ou
                irritadiço, sem conseguir sentir prazer com o que antes era
                agradável, muito cansado, sem energia e com a sensação de
                raciocínio lento. Já as fases de euforia ou mania podem durar
                poucos dias, mas a pessoa se sente literalmente eufórica e
                acelerada, com muita energia, precisando dormir menos que o
                habitual e sentindo como se os pensamentos
                fugissem&nbsp;da&nbsp;cabeça. Em alguns casos, a pessoa pode
                apresentar delírios (crenças que não podem ser compartilhadas)
                de grandeza como o de se achar mais rica do que é e gastar
                grandes somas de dinheiro ou mesmo de minimizar riscos, se
                envolvendo em atividades perigosas como dirigir em alta
                velocidade ou experimentar drogas ilícitas. Existem ainda os
                chamados estados mistos. Nessas fases, há uma mistura de
                sintomas depressivos e maníacos e a descrição mais clássica é a
                de um ‘estado de ira’: a pessoa fica tão irritada e briguenta
                que parece estar irada. Nessa fase é comum o suicídio (a pessoa
                fica com a energia da mania e o pensamento da depressão, uma
                combinação perigosa).
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar o transtorno bipolar do humor?
              </h2>
              <p className="d-none justify">
                Não existe um exame laboratorial ou de imagem para o
                diagnóstico. Ele é feito clinicamente, ou seja, durante a
                consulta médica de acordo com o que o paciente conta e de como
                ele se comporta. Nem sempre o paciente percebe sintomas leves de
                euforia e tende a não falar disso na consulta. Muitas vezes são
                os familiares que percebem com mais nitidez as mudanças de humor
                e podem dar informações cruciais para o diagnóstico. Aliás, esse
                é um diagnóstico difícil e em geral a demora é de 8 a 10 anos
                para o paciente receber um diagnóstico correto.
                <br />
                <br />
                O paciente bipolar tende a ter muito mais episódios depressivos
                que de euforia (no mínimo 3 vezes mais) e geralmente o primeiro
                episódio da doença é do tipo depressivo, ou seja, não há como
                saber que aquela primeira depressão é na verdade uma fase do
                transtorno bipolar. Além disso, quando as fases de euforia são
                mais leves (hipomania), a pessoa tende a achar que aquele padrão
                é ‘o normal para ela’, visto que nessas fases, a pessoa se sente
                muito bem, produtiva e com muita energia.
                <br />
                <br />A sensação de bem-estar nessas fases mais leves
                (hipomania) é tão intensa que às vezes a pessoa quer deixar de
                tomar a medicação para sentir-se assim novamente. Infelizmente,
                a hipomania pode ser ‘a antessala do inferno’ porque cada vez
                que o paciente tem uma fase (de euforia, depressão ou estado
                misto), sua chance de ter outras fases aumenta muito e, como a
                maior parte é do tipo depressiva, ter uma fase maníaca, ainda
                que leve, é sinônimo de entrar num estado depressivo em pouco
                tempo.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Existem tipos de bipolaridade?
              </h2>
              <p className="d-none justify">
                Sim. A classificação americana (DSM-IV) dividiu os{' '}
                <b>bipolares</b> em 4 tipos:
                <br />
                <br />
                <b>Tipo I:</b> aquele que tem fases de depressão, euforia e
                estados mistos.
                <br />
                <br />
                <b>Tipo II:</b> aquele que tem fases de depressão, mas as fases
                de euforia são leves (as chamadas <b>hipomanias</b>).
                <br />
                <br />
                <b>Tipo III:</b> o paciente só tem fase de mania ou hipomania
                quando está em uso de antidepressivos. Sem antidepressivos, ele
                tem depressão, mas não tem mania.
                <br />
                <br />
                <b>Tipo IV:</b> o paciente tem parentes de primeiro grau{' '}
                <b>bipolares</b>, porém não manifestou a doença.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade o transtorno bipolar é mais frequente?
              </h2>
              <p className="d-none justify">
                Geralmente a doença se manifesta no final da adolescência e
                início da fase adulta, contudo existem bipolares de 7 e de 70
                anos. Não há uma regra.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />O
                transtorno bipolar é genético?
              </h2>
              <p className="d-none justify">
                Sim, mas não há um gene específico. Filhos de pais bipolares têm
                aproximadamente 3 a 8 vezes mais chance de desenvolver a doença
                do que a população geral. Quando ambos genitores são bipolares,
                a chance é ainda maior, porém não é 100%. A resposta às
                medicações também pode ter uma influência genética, sobretudo a
                resposta ao lítio.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento da bipolaridade?
              </h2>
              <p className="d-none justify">
                O <b>Transtorno Bipolar do Humor</b> é uma <b>doença crônica</b>{' '}
                e até o momento, sem cura. O tratamento é feito com{' '}
                <b>estabilizadores do humor</b>, que é um grupo ao qual
                pertencem o lítio, alguns remédios para epilepsia e alguns
                antipsicóticos (remédios para esquizofrenia).
                <br />
                <br />
                Tudo depende da fase em que o paciente está e ao longo do
                tratamento as combinações tendem a mudar. Os estabilizadores de
                humor reduzem muito a chance de o paciente ter{' '}
                <b>fases de depressão ou de mania</b>, mas essa chance não chega
                a zero e por isso são necessárias consultas regulares de
                acompanhamento.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar o paciente bipolar?
              </h2>
              <p className="d-none justify">
                A família até pode verificar o uso correto da medicação, porém
                só isso não basta. O mais importante é que a família conheça a
                doença, aceite que o familiar tem um problema e o ajude a
                identificar os sinais de descompensação. Os principais são:
                mudança da velocidade da fala (da tagarelice ao mutismo),
                mudanças rápidas de assunto (o paciente começa falando da caneta
                e termina falando em meditação transcendental) ou insistência
                obsessiva num assunto só (faz uma briga tola render horas),
                alterações no sono (se o paciente passa o dia todo na cama
                prostrado ou se passa a noite em claro no WhatsApp) e
                irritabilidade.
                <br />
                <br />
                Ao identificar qualquer um desses sinais, é preciso falar
                primeiro com o paciente, de modo tranquilo e nunca acusatório.
                Lembre-se de que ninguém é <b>bipolar</b> (nem diabético ou
                hipertenso) porque quer. É uma doença a ser tratada. Depois que
                conversar com o paciente, a família deve trazê-lo para consulta
                psiquiátrica para que o tratamento seja ajustado. Os parentes
                podem ligar para o psiquiatra, mas nunca escondido do paciente,
                mesmo que ele fique bravo. É necessário avisar que está ligando
                para o psiquiatra porque está preocupado e quer ajudar. Usar o
                psiquiatra como ameaça só afasta o paciente do tratamento e
                atrapalha as relações familiares.
                <br />
                <br />
                Quando a família conhece a doença e entende que ela tem controle
                com as medicações, fica mais fácil para o paciente aceitar que
                precisa de tratamento e aceitar a ajuda durante as fases da
                doença.
                <br />
                <br />O <b>bipolar</b> não é incapaz. Suas opiniões e
                sentimentos devem ser considerados e, quando ele está em fase de
                depressão ou de mania, deve-se oferecer apoio para que ele se
                trate, fique bem e pondere as opiniões e decisões que tinha
                quando estava no auge da doença. Durante as fases, a família
                pode ajudar aconselhando o paciente a deixar para depois
                decisões importantes como mudança de carreira, compra de
                imóveis, divórcio ou casamento. É preciso apoiar e seguir as
                orientações do psiquiatra.
              </p>
              <h2 className="mostrar2 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Tenho transtorno bipolar. Posso engravidar?
              </h2>
              <p className="d-none justify">
                Sim! Contudo a medicação precisa ser adequada durante a gestação
                (nem todos os remédios são seguros para o bebê) e o parto
                precisa ser programado. A gestação e especialmente o pós-parto
                são fases de alto risco para descompensação da bipolaridade. As
                mulheres bipolares que não se tratam têm um risco 100 vezes
                maior de ter psicose no pós-parto se comparadas à população
                geral e isso é muito grave.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Bipolar;
