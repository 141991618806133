import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');

  .font-times {
    font-family: 'Times New Roman';
  }
  * {
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    color:#4A4949;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font: 16px 'Montserrat';
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color:inherit;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
    border: none;
    background: transparent;
  }
  h1, h2, h3, h4, h5, h6, input, button{
    font-family: 'Montserrat', sans-serif;
  }
  hr{
    border: none;
    border-top: 1px solid #999;
  }
  .mt {
    margin-top: 20px;
  }
  .text-center{
    text-align: center;
  }
  .mobile-only{
    display: none;
  }

  .font-gouldy {
    font-family: 'Gouldy';
  }
  .font-footer{
    font-size: 0.8rem !important;
  }

  .title{
    line-height: 0.9!important;
    font-size: 5rem!important;
  }

  @media screen and (max-width: 768px) {
    .title{
    line-height: 0.9!important;
    font-size: 2rem!important;
  }

  .h1-small{
    font-size: 1.1rem!important;
  }

  .h4-small{
    font-size: 0.7rem!important;
  }
  }

`;
