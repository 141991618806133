import React from 'react';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import GDPRPanel from '~/components/GDPRPanel';

import { Container } from './styles';
import logoFooter from '~/assets/logo-footer.png';

function Footer() {
  return (
    <>
      <Container>
        <div className="bg-footer pb-5 pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="d-flex prof justify-content-end align-items-center h-100">
                  <div className="text-right pr-lg-5 mr-lg-5">
                    {/* <h3 className="prof font-gouldy mb-0">
                      <span className="prof-dra font-gouldy font-weight-light">
                        Prof<sup className="a-sup">a</sup> Dr
                        <sup className="a-sup">a </sup>
                      </span>
                      Priscila Rocco
                    </h3> */}
                    <img
                      src={logoFooter}
                      className="tam-350"
                      alt="Logo Profa Dra Priscila Rocco"
                    />
                    <p className="crm mr-3">CRM 100.765 (SP)</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-10">
                <h3>Contato</h3>
                <p>
                  Endereço: R. Alves Guimarães, 462 - cj. 43 - Pinheiros
                  <br />
                  São Paulo - SP, 05410-000
                </p>
                <p>
                  <FaPhoneAlt />
                  &nbsp;Telefone: <a href="tel:+551132850770">(11) 3285-0770</a>
                  <br />
                  <FaWhatsapp />
                  &nbsp;WhatsApp:{' '}
                  <a
                    href="https://api.whatsapp.com/send/?phone=5511940317994&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es&type=phone_number&app_absent=0"
                    target="blank"
                  >
                    (11) 94031-7994
                  </a>
                </p>
                <p>
                  E-mail:{' '}
                  <a href="mailto:contato@drapriscilarocco.com.br">
                    contato@drapriscilarocco.com.br
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <p className="text-center font-footer py-2">
        @ Dra. Priscila Rocco | TODOS OS DIREITOS RESERVADOS
      </p>
      <GDPRPanel />
    </>
  );
}

export default Footer;
