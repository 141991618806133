import styled from 'styled-components';

export const Container = styled.div`
  .banner {
    margin-top: 4.5%;
    margin-bottom: 2%;
  }
  h1 {
    color: #5a853d;
    font-size: 4.5rem;
    line-height: 70px;
    small {
      font-size: 2.5rem;
      line-height: 1px;
    }
  }

  @media screen and (max-width: 1441px) {
    .banner {
      margin-top: 84px;
    }
    p {
      font-weight: 300;
    }
  }

  @media screen and (max-width: 1024px) {
    h1 {
      color: #5a853d;
      font-size: 3.5rem;
      line-height: 50px;
      small {
        font-size: 1.5rem;
        line-height: 1px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      margin-top: 77px;
    }
    h1 {
      margin-top: 1rem;
      font-size: 2.5rem;
      small {
        font-size: 1rem;
      }
    }
    .mobile-none {
      display: none;
    }
  }
`;

export const Bio = styled.div`
  p {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 769px) {
    .justify {
      text-align: justify;
    }
  }
`;

export const Bgray = styled.div`
  background-color: #f6f6f6;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  h2 {
    color: #5a853d;
    font-family: 'Times New Roman';
  }
  .marcador {
    font-size: 1.2rem;
    margin-left: 1.7rem;
  }
  .marcador:before {
    content: '';
    border-bottom-right-radius: 100%;
    position: absolute;
    left: -25px;
    top: 7px;
    background-color: #5a853d;
    width: 15px;
    height: 15px;
  }
  @media screen and (max-width: 768px) {
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    h2 {
      font-size: 1.5rem;
    }
  }
`;
export const Publicacoes = styled.div`
  h2 {
    color: #5a853d;
    font-family: 'Times New Roman';
  }
  p {
    font-size: 1rem;
  }
  a {
    color: #5a853d !important;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1441px) {
    p {
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 478px) {
    a {
      font-size: 1rem;
    }
  }
`;
export const Contato = styled.div`
  background-color: #f6f6f6;
  h3 {
    color: #5a853d;
    font-size: 1.8rem;
    font-family: 'Times New Roman';
  }
  a {
    font-size: 1.2rem;
    color: #fff !important;
    text-decoration: none !important;
    background-color: #415c2f;
    border-radius: 20px;
    padding: 15px 60px;
    text-align: center;
  }
  @media screen and (max-width: 1441px) {
    a {
      font-size: 1rem;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 768px) {
    .w-100-mobile {
      width: 100%;
    }
    .text-center-mobile {
      text-align: center;
    }

    .psicoterapia {
      text-align: center;
      margin: 3rem;
      font-size: 1.3rem;
    }
    .mobile-none {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .desk-none {
      display: none;
    }
  }
`;
