import styled from 'styled-components';

export const Container = styled.div`
  .banner {
    margin-top: 4.5%;
    margin-bottom: 2%;
  }
  h1 {
    color: #5a853d;
    font-size: 2rem;
    font-family: 'Times New Roman';
  }
  .btn {
    height: 90%;
    background-color: #415c2f;
    color: #fff;
    font-size: 1.2rem;
  }
  input,
  textarea,
  .btn {
    border-radius: 15px !important;
  }
  p {
    font-size: 1.1rem;
  }

  .btn-gray {
    height: 90%;
    background-color: #8f8f8f;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 15px !important;
  }

  @media screen and (max-width: 1441px) {
    .banner {
      margin-top: 6%;
    }
  }

  @media screen and (max-width: 768px) {
    .form-row {
      margin-top: 3rem;
    }
    .banner {
      margin-top: 77px;
    }
    .h-fit-content-mobile {
      height: fit-content;
    }
    .btn-gray {
      font-size: 1rem;
    }
  }
`;
