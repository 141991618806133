import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Panico() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar6')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar6'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: Síndrome do pânico: Conheça os tratamentos e sintomas
          desse transtorno
        </title>
        <meta
          name="description"
          content="Você sabe o que é Síndrome do Pânico? A Dra. Priscila Rocco explica os tratamentos e as formas de identificar o transtorno de pânico."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Síndrome do pânico:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    A síndrome do pânico é também conhecida como o transtorno de
                    pânico.
                  </p>
                  <p className="px-5 texto-desk">
                    A síndrome do pânico é também conhecida como o transtorno de
                    pânico. Ela é uma doença em que ocorre uma hiperativação da
                    circuitaria cerebral responsável pelo medo e que envolve uma
                    região chamada amígdala (diferente da amígdala que o
                    otorrino trata quando somos crianças). Basicamente, o que
                    ocorre é que o alarme dispara na ausência do ladrão. Cerca
                    de 10% da população tem crises de pânico ao longo da vida e
                    uma única crise não define o diagnóstico de síndrome do
                    pânico.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                No transtorno, as crises se repetem. Elas são caracterizadas
                pela presença de sintomas psíquicos (sensação de que vai morrer
                ou enlouquecer) e físicos (palpitação, falta de ar, tremedeira,
                sudorese, formigamentos, boca seca) que aparecem ‘do nada’,
                alcançam uma intensidade máxima em 10 minutos e desaparecem
                espontaneamente em até 30 minutos. Se a pessoa tem sintomas de
                pânico quando tem que falar em público, ou seja, tem uma causa
                para isso, o diagnóstico é outro. As crises de pânico
                obrigatoriamente surgem ‘do nada’.
              </p>
              <p className="text-justify texto-desk">
                É frequente (75% dos casos) o paciente associar a crise com o
                lugar onde ela ocorreu, por exemplo, o mercado. A partir daí,
                desenvolve um quadro chamado agorafobia, que é o medo de
                determinados tipos de lugares onde ele teve a crise e onde ele
                julgue que é difícil ter socorro. O paciente passa a evitar
                esses lugares e passa a ter fobia de sair de casa por conta das
                crises.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Ela é uma doença em que ocorre uma hiperativação da
                circuitaria cerebral responsável pelo medo e que envolve uma
                região chamada amígdala (diferente da amígdala que o otorrino
                trata quando somos crianças). Basicamente, o que ocorre é que o
                alarme dispara na ausência do ladrão. Cerca de 10% da população
                tem crises de pânico ao longo da vida e uma única crise não
                define o diagnóstico de síndrome do pânico. No transtorno, as
                crises se repetem. Elas são caracterizadas pela presença de
                sintomas psíquicos (sensação de que vai morrer ou enlouquecer) e
                físicos (palpitação, falta de ar, tremedeira, sudorese,
                formigamentos, boca seca) que aparecem ‘do nada’, alcançam uma
                intensidade máxima em 10 minutos e desaparecem espontaneamente
                em até 30 minutos. Se a pessoa tem sintomas de pânico quando tem
                que falar em público, ou seja, tem uma causa para isso, o
                diagnóstico é outro. As crises de pânico obrigatoriamente surgem
                ‘do nada’. É frequente (75% dos casos) o paciente associar a
                crise com o lugar onde ela ocorreu, por exemplo, o mercado. A
                partir daí, desenvolve um quadro chamado agorafobia, que é o
                medo de determinados tipos de lugares onde ele teve a crise e
                onde ele julgue que é difícil ter socorro. O paciente passa a
                evitar esses lugares e passa a ter fobia de sair de casa por
                conta das crises.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar os sintomas do transtorno de pânico?
              </h2>
              <p className="d-none justify">
                Como nas outras doenças psiquiátricas, o diagnóstico é clínico e
                os exames são feitos para excluir outras causas como
                hipertireoidismo, arritmias e feocromocitoma (tumor na glândula
                adrenal que imita crises de pânico). Obviamente ninguém está
                proibido de ter mais de uma doença. O paciente pode ter um
                problema no coração e síndrome do pânico ao mesmo tempo. Nesses
                casos, psiquiatra e cardiologista trabalham juntos.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade o transtorno do pânico é mais frequente?
              </h2>
              <p className="d-none justify">
                Geralmente na fase de adulto jovem. Raramente aparece na
                infância ou em idosos.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento?
              </h2>
              <p className="d-none justify">
                O tratamento é feito com terapia comportamental (exposição aos
                sintomas – o paciente aprende a relaxar e deixar a crise passar
                sem medicação) e a medicação pode ou não ser associada. Embora
                algumas diretrizes americanas preconizem o uso de
                benzodiazepínicos (calmantes tarja preta), eles devem ser
                evitados. Quando o paciente está em crise e toma um desses
                remédios (alprazolam, clonazepam, diazepam...), o efeito é
                imediato e a crise passa como mágica. O problema é que aí o
                paciente vira escravo do remédio. Já não consegue sair de casa
                sem ter um comprimido na bolsa e, em pouco tempo, vai precisando
                de doses maiores porque esse tipo de remédio funciona de modo
                parecido com o álcool (induz tolerância): ninguém começa tomando
                1 litro de pinga por dia, mas muitos que começam com uma
                cervejinha de fim de semana terminam assim.
                <br />
                <br />
                O tratamento medicamentoso que pode ser associado à terapia de
                exposição é feito com antidepressivos, que vão induzindo ao
                longo do tempo uma diminuição no número e intensidade das
                crises. O antidepressivo não alivia a crise quando ela aparece.
                Ele previne que ela apareça.
                <br />
                <br />A ideia não é tomar remédio para sempre: com uma boa
                terapia de exposição, o paciente aprende a controlar os sintomas
                sozinho e o antidepressivo pode ser retirado depois de cerca de
                1 ano.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Quem tem problema no coração pode tomar antidepressivo?
              </h2>
              <p className="d-none justify">
                Sim! Mas não todos os antidepressivos. Alguns podem provocar
                arritmias, especialmente em idosos. Se a medicação de escolha
                tem esse risco, o psiquiatra pede um eletrocardiograma na
                avaliação inicial, para ver se o paciente é propenso a ter esse
                tipo de efeito colateral.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar o paciente com pânico?
              </h2>
              <p className="d-none justify">
                O tratamento do pânico inclui exposição ao que é aflitivo e pode
                desencadear as crises como idas ao mercado (quando além de
                pânico o paciente tem agorafobia) e caminhadas mais intensas que
                aceleram o coração e a respiração (para imitar o que acontece na
                crise sem motivo aparente). É essencial que o paciente entenda
                que apesar da sensação de morte iminente que aparece nas crises,
                ele não vai morrer.
                <br />
                <br />
                Uma crise dura cerca de meia hora e passa sozinha. Por isso, a
                família pode ajudar o paciente a se acalmar, esperar a crise
                passar e, sempre que possível, ajudar o paciente a se expor. Uma
                família que permite que o paciente fique trancado em casa,
                fazendo tudo por ele e que o leva ao pronto-socorro ao menor
                desconforto só está reforçando os sintomas e atrapalhando o
                tratamento.
              </p>
              <h2 className="mostrar6 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Tenho transtorno de pânico. Posso engravidar?
              </h2>
              <p className="d-none justify">
                Tomando tarja-preta, não! Dependendo do antidepressivo, sim!
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Panico;
