import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Dismorfico() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar9')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar9'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Especialidades: Você sabe o que é dismorfia corporal? </title>
        <meta
          name="description"
          content="Saiba o que é dismorfia corporal, como é esse transtorno, as causas e os tratamentos."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Transtorno dismórfico corporal:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile justify">
                    A dismorfia corporal ou transtorno dismórfico corporal é uma
                    doença parecida com o TOC na medida em que o paciente fica
                    obsessivo com alguma parte do corpo.
                  </p>
                  <p className="px-5 texto-desk justify">
                    A dismorfia corporal ou transtorno dismórfico corporal é uma
                    doença parecida com o TOC na medida em que o paciente fica
                    obsessivo com alguma parte do corpo, insistindo num defeito
                    que, ou não existe ou não tem a dimensão que o paciente
                    imagina.
                    <br /> É frequente em consultórios de cirurgiões plásticos,
                    em que o paciente tem uma expectativa irreal do resultado
                    cirúrgico e nunca se contenta, insistindo em múltiplas
                    cirurgias.
                    <br />
                    <br />
                    Essa doença também se parece com a anorexia nervosa, com a
                    diferença de que, nessa última, a obsessão é especificamente
                    com a magreza, que nunca é o suficiente para o paciente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="px-3 px-md-5 texto-mobile justify">
                É frequente em consultórios de cirurgiões plásticos, em que o
                paciente tem uma expectativa irreal do resultado cirúrgico e
                nunca se contenta, insistindo em múltiplas cirurgias.
                <br />
                <br />
                Essa doença também se parece com a anorexia nervosa, com a
                diferença de que, nessa última, a obsessão é especificamente com
                a magreza, que nunca é o suficiente para o paciente.
              </p>
              <h2 className="mostrar9 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar a dismorfia corporal?
              </h2>
              <p className="d-none justify">
                O paciente insiste sempre no mesmo assunto daquele determinado
                aspecto da aparência física e ‘pedindo opinião’ de familiares e
                amigos sobre isso. Mesmo reassegurado de que não há nada de
                errado com a aparência, o paciente não desiste e tenta convencer
                a todos de que tem um defeito físico enorme.
              </p>
              <h2 className="mostrar9 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade o transtorno dismórfico corporal é mais frequente?
              </h2>
              <p className="d-none justify">
                A princípio em qualquer idade, mas é muito raro em crianças. É
                frequente o quadro começar logo depois de uma cirurgia plástica
                ou se agravar depois de uma.
              </p>
              <h2 className="mostrar9 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento da dismorfia corporal?
              </h2>
              <p className="d-none justify">
                Depende da gravidade e de outras características do paciente.
                Pode incluir alguns antidepressivos e antipsicóticos. A
                psicoterapia também tem um papel importante nesses casos e deve
                ter como foco tanto a percepção real do corpo quanto o fim da
                necessidade de reasseguramento.
              </p>
              <h2 className="mostrar9 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar?
              </h2>
              <p className="d-none justify">
                A família ajuda na medida em que não fica discutindo o assunto
                com o paciente. O ideal é dizer que já expressou sua opinião e
                que o assunto está encerrado. Também não se deve incentivar
                “romarias” por diferentes profissionais porque isso só reforça o
                comportamento obsessivo do paciente.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Dismorfico;
