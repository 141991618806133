import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 350px;
  position: sticky;
  display: ${props => (props.visible ? 'flex' : 'none')};
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  background: #eceaea;
  padding: 15px;
  overflow-y: auto;
  justify-content: center;
  align-items: center;

  span {
    color: #5a853d;
    font-weight: bold;
  }

  a {
    color: #5a853d;
    transition-duration: 0.3s;
    font-weight: bold;

    :hover {
      color: ${darken(0.1, '#5a853d')} !important;
    }
  }

  p {
    margin: 0px;
    margin-right: 15px;
    line-height: 20px;
    color: #1d1d1b;

    @media screen and (max-width: 768px) {
      margin-right: 10px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 40px;
    background: #5a853d;
    border: none;
    border-radius: 10px;
    color: #fff;
    transition: background 0.3s;
    font-size: 1.2rem;
    padding: 5px 10px;
    margin-top: 0px;

    :hover {
      background: ${darken(0.1, '#5a853d')};
    }
  }
`;
