import styled from 'styled-components';

export const Container = styled.div`
  .banner {
    margin-top: 4.5%;
    margin-bottom: 2%;
  }

  h1,
  h2 {
    font-size: 1.3rem;
    font-weight: normal;
  }

  a {
    color: #fff !important;
    background-color: #415c2f;
    padding: 3% 15%;
    font-size: 1.2rem;
    border-radius: 20px;
  }

  @media screen and (max-width: 1441px) {
    .banner {
      margin-top: 84px;
    }
  }

  @media screen and (max-width: 768px) {
    .form-row {
      margin-top: 3rem;
    }
    .mb-mobile {
      margin-bottom: 3rem;
    }
    .mobile-w-100 {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .banner {
      margin-top: 77px;
      text-align: left;
    }
    h1,
    h2 {
      font-size: 1rem;
    }
  }
`;
