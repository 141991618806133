import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Conteudo } from './styles';
import setaClosed from '~/assets/setaClosed.png';
import setaOpened from '~/assets/setaOpened.png';

function Obesidade() {
  const [elements, setElements] = useState(
    document.querySelectorAll('.mostrar4')
  );
  const location = useLocation();
  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.onclick = () => {
          const seta = element.getElementsByTagName('img');
          const brotherElement = element.nextElementSibling;
          if (brotherElement.classList.contains('d-none')) {
            element.nextElementSibling.classList.remove('d-none');
            seta[0].src = setaOpened;
          } else {
            element.nextElementSibling.classList.add('d-none');
            seta[0].src = setaClosed;
          }
        };
      });
    } else {
      setElements(document.querySelectorAll('.mostrar4'));
    }
  }, [elements, location]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Especialidades: Você sabe o que é a obesidade relacionada à ansiedade
          e depressão?{' '}
        </title>
        <meta
          name="description"
          content="Conheça os sinais e tratamentos da obesidade relacionada à ansiedade e depressão, por Dra. Priscila Rocco."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 bg-gray">
              <div className="row w-100 m-0 align-items-center h-100 flex-row-reverse">
                <div className="col-12 col-md col-lg-10 px-0 px-md-5 ">
                  <h1 className="px-5">
                    Obesidade relacionada à ansiedade:
                    <br /> conheça os sintomas e tratamentos
                  </h1>
                  <p className="px-5 texto-mobile">
                    A obesidade é uma doença (ou seja, não é falta de vergonha!)
                    definida através do Índice de Massa Corporal (IMC).
                  </p>
                  <p className="px-5 texto-desk">
                    A obesidade é uma doença (ou seja, não é falta de vergonha!)
                    definida através do Índice de Massa Corporal (IMC).
                    <br /> Esse índice é o resultado da seguinte conta: (peso em
                    kg) ÷ (altura em metros ao quadrado).
                    <br /> Por exemplo: uma pessoa de 70 kg que tem 1,60 m de
                    altura tem um IMC = 27,3.
                    <br /> O índice normal vai de 20 a 25 e entre 25 e 30 está o
                    chamado sobrepeso.
                    <br /> Ter um IMC ≥ 30 significa que a pessoa está obesa.
                    <br /> Obesidade, ansiedade e depressão são quadros muito
                    frequentes na população e um aumenta o risco do outro.
                    <br /> Ou seja, quem tem obesidade é mais propenso a ter
                    depressão e ansiedade e quem tem ansiedade e/ou depressão
                    tem mais chance de desenvolver obesidade.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Conteudo>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p className="text-justify texto-desk">
                No Brasil, mais da metade da população tem sobrepeso e mais de
                15% tem obesidade (dados do VIGITEL para 2015). Já a taxa de
                pessoas com alguma doença ansiosa (ansiedade generalizada,
                pânico, TOC, agorafobia, estresse pós-traumático, etc.) na
                população de São Paulo é de quase 20% e, de deprimidos, cerca de
                10%, os dados são do estudo Megacity publicado em 2012. Se
                contabilizarmos só os sintomas de ansiedade, sem uma doença
                propriamente dita, essa taxa é bem mais elevada.
              </p>
              <p className="text-justify texto-desk">
                O Transtorno de Compulsão Alimentar e alguns casos de Bulimia
                podem estar associados à obesidade. Quando a obesidade acontece
                em decorrência de uma doença ou mesmo de um tratamento
                psiquiátrico (algumas medicações usadas para tratar ansiedade e
                depressão engordam de verdade), o tratamento deve ser voltado
                para ambas as doenças e, para isso, psiquiatra,
                endocrinologista, nutricionista, psicólogo e educador físico
                devem trabalhar juntos.
              </p>
              <p className="px-3 px-md-5 texto-mobile">
                Esse índice é o resultado da seguinte conta: (peso em kg) ÷
                (altura em metros). Por exemplo: uma pessoa de 70 kg que tem
                1,60 m de altura tem um IMC = 27,3. O índice normal vai de 20 a
                25 e, entre 25 e 30 está o chamado sobrepeso. Ter um IMC ≥ 30
                significa que a pessoa está obesa. Obesidade, ansiedade e
                depressão são quadros muito frequentes na população e um aumenta
                o risco do outro. Ou seja, quem tem obesidade é mais propenso a
                ter depressão e ansiedade e quem tem ansiedade e/ou depressão
                tem mais chance de desenvolver obesidade. No Brasil, mais da
                metade da população tem sobrepeso e mais de 15% tem obesidade
                (dados do VIGITEL para 2015). Já a taxa de pessoas com alguma
                doença ansiosa (ansiedade generalizada, pânico, TOC, agorafobia,
                estresse pós-traumático, etc.) na população de São Paulo é de
                quase 20% e, de deprimidos, cerca de 10%, os dados são do estudo
                Megacity publicado em 2012. Se contabilizarmos só os sintomas de
                ansiedade, sem uma doença propriamente dita, essa taxa é bem
                mais elevada.
                <br />
                <br />O Transtorno de Compulsão Alimentar e alguns casos de
                Bulimia podem estar associados à obesidade. Quando a obesidade
                acontece em decorrência de uma doença ou mesmo de um tratamento
                psiquiátrico (algumas medicações usadas para tratar ansiedade e
                depressão engordam de verdade), o tratamento deve ser de ambas
                doenças e, para isso, psiquiatra, endocrinologista,
                nutricionista, psicólogo e educador físico devem trabalhar
                juntos.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como identificar?
              </h2>
              <p className="d-none justify">
                Via de regra o paciente só identifica a obesidade no espelho e,
                como ela só tende a aumentar, por vezes nem tem coragem de se
                pesar e dimensionar o problema. Existe uma tendência enviesada
                entre médicos e pacientes de atribuir os sintomas depressivos à
                insatisfação com a imagem corporal e a baixa autoestima e
                atribuir a obesidade exclusivamente à ansiedade. Vamos lembrar
                que os 2 quadros são doenças com características comuns (como o
                aumento de marcadores inflamatórios no sangue e alterações na
                circuitaria de recompensa do cérebro) e devem ser tratadas ao
                mesmo tempo.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Em qual idade a obesidade é mais frequente?
              </h2>
              <p className="d-none justify">
                A obesidade infantil tem crescido muito no mundo inteiro e isso
                aumenta a chance de obesidade na fase adulta. Ainda assim,
                muitos pacientes desenvolvem a obesidade mais tardiamente,
                sobretudo as mulheres depois da menopausa.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Obesidade relacionada a ansiedade e depressão é genética?
              </h2>
              <p className="d-none justify">
                Sim. Obesidade, ansiedade e depressão tem influência de
                múltiplos genes, mas é fundamental ressaltar que não herdamos
                apenas genes. Herdamos hábitos alimentares. Quando os pais têm
                uma alimentação pouco saudável, os filhos herdam esses hábitos
                e, quando os pais tentam forçar uma alimentação saudável para os
                filhos sem mudar os próprios hábitos, o efeito é ainda pior,
                porque a criança se sente punida pelas frutas e torturada pelos
                legumes.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como é feito o tratamento da obesidade relacionada a ansiedade e
                depressão?
              </h2>
              <p className="d-none justify">
                O tratamento dessas doenças é demorado e exige uma integração
                fina entre os profissionais que vão assistir esse paciente, além
                do empenho do próprio paciente, é claro. Se todos os
                profissionais envolvidos: endocrinologista, psiquiatra,
                psicólogo, nutricionista e educador físico não se falarem, pode
                esquecer o tratamento.
                <br />
                <br />
                Alguns casos mais graves precisam de tratamento cirúrgico.
                Nesses casos, o tratamento psicológico é essencial, tanto no
                preparo para a cirurgia quanto no seguimento desta, e o
                cirurgião precisa estar em contato com o resto da equipe que
                assiste o paciente. Reduzir o peso em 30 a 40% rapidamente como
                ocorre no pós-cirúrgico provoca profundas alterações na imagem
                corporal do indivíduo e exige mudança de comportamento para que
                essa redução de peso possa se manter ao longo do tempo.
                <br />
                <br />
                Quando o paciente não está psicologicamente preparado para essas
                mudanças, a chance de reganho de peso e complicações
                psiquiátricas como o desenvolvimento de alcoolismo e depressão,
                aumenta exponencialmente. A cirurgia bariátrica pode ser feita
                por diversas técnicas diferentes (bypass, gastrectomia vertical,
                banda gástrica, duodenal switch, entre outras) e a escolha da
                técnica depende não só de quanto peso o paciente precisa perder,
                mas também das comorbidades (doenças associadas como refluxo
                gastresofágico e diabetes). Quando existe associação com o
                Transtorno de Compulsão Alimentar, esse deve ser
                obrigatoriamente tratado antes da cirurgia.
                <br />
                <br />O tratamento multidisciplinar é sem dúvida um investimento
                e uma escolha entre privilegiar a qualidade de vida ou cuidados
                paliativos com pressão alta, diabetes, câncer e outras
                complicações da obesidade.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Como a família pode ajudar?
              </h2>
              <p className="d-none justify">
                Se a família compreender que a obesidade é uma doença e não uma
                falha de caráter e não segregar o paciente dando a ele um prato
                de salada enquanto pede uma pizza, já ajuda muito. Também ajuda
                não aumentar o nível de estresse e ansiedade relacionado à
                alimentação.
                <br />
                <br />
                A família não deve criticar o prato do paciente com a desculpa
                de ser uma “crítica construtiva”. Uma avalanche de “críticas
                construtivas” é altamente destrutiva: prejudica o relacionamento
                entre os familiares e a relação do paciente com a comida. Cada
                um cuida da própria alimentação e o paciente cuida da dele, ou
                seja, comer pizza ou comida de verdade (aquela que a gente faz
                em casa, sem aditivos, corantes, espessantes, etc.) é escolha
                dele.
                <br />
                <br />
                Dieta, no sentido de restrição calórica, só está indicada para o
                paciente obeso, mas, alimentação saudável está indicada para a
                família toda. Incluir vegetais na alimentação não é castigo, é
                qualidade de vida. Não discrimine, apoie.
              </p>
              <h2 className="mostrar4 justify">
                <img src={setaClosed} alt="seta" className="seta " />
                Tenho obesidade, ansiedade e depressão. Posso engravidar?
              </h2>
              <p className="d-none justify">
                Sim! Existem antidepressivos que podem ser usados na gestação e
                amamentação e a gestação não é impedimento para mudar hábitos
                alimentares. No entanto, a obesidade aumenta o risco de
                complicações na gravidez como o diabetes gestacional e por isso
                é necessário dosar os riscos e planejar essa fase tão especial
                da vida da mulher.
              </p>
            </div>
          </div>
        </div>
      </Conteudo>
    </>
  );
}

export default Obesidade;
