import styled from 'styled-components';

export const Container = styled.div`
  .banner {
    margin-top: 4.5%;
    margin-bottom: 2%;
  }
  h1 {
    color: #5a853d;
  }
  p {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1441px) {
    p {
      font-size: 1rem;
    }
    .banner {
      margin-top: 84px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner {
      margin-top: 13vh;
    }
  }
`;
export const Bgray = styled.div`
  background-color: #f6f6f6;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  .carousel-indicators {
    display: none;
  }
  h2 {
    color: #5a853d;
    font-family: 'Times New Roman';
  }
  a h2 {
    position: absolute;
    bottom: -1px;
    background-color: #eceaeae6;
    text-align: left;
    width: 9.1%;
    padding: 15px 0px 15px 20px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 8px;
    color: #5a853d;
    font-family: 'Montserrat';
  }
  .rec-arrow {
    background-color: transparent !important;
    box-shadow: 0 0 0 0 transparent !important;
    color: #000 !important;
  }
  .rec-dot {
    background-color: transparent !important;
    box-shadow: 0 0 0 !important;
    cursor: default !important;
  }
  @media screen and (max-width: 768px) {
    border-bottom-left-radius: 90px;
    a p {
      width: 8.5%;
    }
    .rec-arrow {
      width: 30px;
      height: 30px;
      min-width: 30px;
      line-height: 30px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    a h2 {
      width: 7%;
    }
  }
`;
