import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Conteudo, Bgray } from './styles';

import atendimento1 from '~/assets/informacoes/atendimento-1.png';
import atendimento1Mobile from '~/assets/informacoes/atendimento-1-mobile.png';
import atendimento2 from '~/assets/informacoes/atendimento-2.png';
import atendimento2Mobile from '~/assets/informacoes/atendimento-2-mobile.png';
import atendimento3 from '~/assets/informacoes/atendimento-3.png';
import atendimento3Mobile from '~/assets/informacoes/atendimento-3-mobile.png';
import atendimento4 from '~/assets/informacoes/atendimento-4.png';
import atendimento4Mobile from '~/assets/informacoes/atendimento-4-mobile.png';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Priscila Rocco: psiquiatra e psicoterapeuta – Tipos de atendimento
        </title>
        <meta
          name="description"
          content="Dra. Priscila Rocco atua com consulta psiquiátrica no consultório e domiciliar e também realiza avaliação hospitalar e internação psiquiátrica"
        />
      </Helmet>
      <Conteudo>
        <div className="bg-green">
          <div className="container">
            <h1 className="text-center py-lg-5 py-4 mb-0">
              Tipos de atendimento
            </h1>
          </div>
        </div>
      </Conteudo>

      <Bgray>
        <div className="container py-5">
          <div className="row w-100 m-0 px-4">
            <div className="row row-tipo my-4">
              <div className="col-md-6">
                <h2 className="mt-4">Consulta médica</h2>
                <p>
                  Realizada preferencialmente no consultório, consiste em
                  entrevista com o paciente e com familiares (quando houver
                  necessidade de acompanhante), exame psíquico e análise de
                  resultados de exames (quando houver). A consulta tem duração
                  estimada de 1 hora. Para pacientes com alguma limitação física
                  ou psicológica que impeçam a vinda ao consultório, pode ser
                  realizado o atendimento online ou domiciliar. Devido à
                  necessidade de deslocamento, as consultas domiciliares
                  precisam ser agendadas com antecedência e tem honorários
                  diferenciados.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src={atendimento1}
                  className="d-none d-lg-block"
                  alt="Consulta médica"
                />
                <img
                  src={atendimento1Mobile}
                  className="d-lg-none"
                  alt="Consulta médica"
                />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-6 text-center">
                <img
                  src={atendimento2}
                  className="d-none d-lg-block"
                  alt="Psicoterapia"
                />
                <img
                  src={atendimento2Mobile}
                  className="d-lg-none"
                  alt="Psicoterapia"
                />
              </div>
              <div className="col-md-6">
                <h2 className="mt-4">Psicoterapia</h2>
                <p>
                  Realizada preferencialmente no consultório, onde o ambiente
                  propicia mais privacidade e espaço físico para utilização de
                  técnicas de psicodrama e de terapia cognitivo comportamental
                  que se façam necessárias. A frequência mínima é semanal e as
                  sessões têm duração de 50 minutos.
                </p>
              </div>
            </div>
            <div className="row row-tipo my-4">
              <div className="col-md-6">
                <h2 className="mt-4">Avaliação hospitalar (interconsulta)</h2>
                <p>
                  Indicada para pacientes internados por motivos clínicos
                  (diabetes, câncer, etc.) que necessitem de assistência
                  psiquiátrica. Alguns hospitais dispõem de uma equipe própria
                  de retaguarda, mas é direito do paciente ser avaliado por seu
                  médico particular, onde quer que esteja. Esse tipo de
                  avaliação requer o contato imediato com a equipe clínica
                  responsável pelo paciente dentro do hospital.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src={atendimento3}
                  className="d-none d-lg-block"
                  alt="Avaliação hospitalar"
                />
                <img
                  src={atendimento3Mobile}
                  className="d-lg-none"
                  alt="Avaliação hospitalar"
                />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-6 text-center">
                <img
                  src={atendimento4}
                  className="d-none d-lg-block"
                  alt="Internação psiquiátrica"
                />
                <img
                  src={atendimento4Mobile}
                  className="d-lg-none"
                  alt="Internação psiquiátrica"
                />
              </div>
              <div className="col-md-6">
                <h2 className="mt-4">Internação psiquiátrica</h2>
                <p>
                  Indicada para pacientes cuja gravidade do quadro os obrigue a
                  permanecer sob vigilância para evitar riscos para si mesmos ou
                  para terceiros. Algumas clínicas exigem que o paciente seja
                  acompanhado exclusivamente por profissionais da instituição.
                  Também nesses casos, é direito do paciente ser avaliado por
                  seu médico particular, que fará o contato com o colega
                  vinculado ao hospital psiquiátrico em questão para adequar o
                  tratamento.
                </p>
              </div>
            </div>

            <div className="col-12 text-center mt-4">
              <Link
                to={`${process.env.PUBLIC_URL}/quando-procurar-psiquiatra`}
                className="btn"
              >
                Quando procurar um&nbsp;psiquiatra?
              </Link>
            </div>
          </div>
        </div>
      </Bgray>
    </>
  );
}

export default Home;
