import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container } from './styles';

import Banner from '~/assets/contato/consultorio.png';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Priscila Rocco: Consultório psiquiátrico</title>
        <meta
          name="description"
          content="A clínica propicia condições ideais para recebê-lo com toda privacidade, individualmente ou grupos familiares quando indicado."
        />
      </Helmet>
      <Container>
        <div className="banner">
          <div className="row w-100 m-0">
            <div className="col-md-6 p-0 mb-mobile">
              <img src={Banner} alt="Banner consultório" />
            </div>
            <div className="col-lg-6">
              <div className="row align-items-center h-100">
                <div className="col-12 col-lg-8 px-5">
                  <h1 className="h6 mb-5">
                    Ambiente pronto para recebê-lo com toda privacidade,
                    individualmente ou com grupos familiares, quando indicado.
                  </h1>
                  <h2 className="h6 mb-5">
                    O consultório está localizada na
                    <b> Rua Alves Guimarães, 462 - cj. 43</b>, em
                    <b> Pinheiros</b>, próximo ao Instituto de Psiquiatria do
                    Hospital das Clínicas.
                  </h2>
                  <Link
                    to={`${process.env.PUBLIC_URL}/contato`}
                    className="mobile-w-100 py-lg-4 py-3 mb-4 mb-md-0 font-weight-bold"
                  >
                    Entre em Contato
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Home;
